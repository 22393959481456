import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ProductsFilter } from 'dto/product';
import { useHistory } from 'react-router-dom';
import { TransField } from 'i18n/trans/field';
import {
  getProducts,
  setProductsFilter,
} from 'features/product/productActions';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  ConditionField,
  FormControl,
  FormProvider,
  Icon,
  formSubmit,
  SearchForm,
  SelectField,
  TextField,
  useForm,
  SelectOwnerField,
} from '@fleet/shared';
import { Dropdown } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type { Option } from '@fleet/shared/dto/option';
import { fetchProductCategories } from 'features/product/productService';
import {
  productCategoriesSelector,
  productsFilterSelector,
} from 'features/product/productSelector';
import dtid from 'helpers/dtid';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';
import { pick } from 'lodash';

interface ProductsSearchFormProps {}

export const ProductsSearchForm: FC<ProductsSearchFormProps> = () => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [productCategoryOptions, setProductCategoryOptions] = useState<
    Array<Option<string>>
  >([]);
  const productCategories = useSelector(productCategoriesSelector);
  const history = useHistory();
  const filter = useSelector(productsFilterSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: Partial<ProductsFilter>) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getProducts({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const defaultValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId }),
    [currentBusinessEntityId]
  );

  const initialValues = useMemo(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const { form, handleSubmit } = useForm<ProductsFilter>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });
  const { ownerId } = form.getState().values;

  const fetchProductCategoryOptions = useCallback(async (ownerId) => {
    setProductCategoryOptions(
      (await fetchProductCategories(ownerId)).map(({ id, category }) => ({
        value: id,
        label: category.name,
      }))
    );
  }, []);

  useEffect(() => {
    fetchProductCategoryOptions(ownerId);
  }, [fetchProductCategoryOptions, ownerId]);

  useEffect(() => {
    form.change('categoryId');
  }, [form, ownerId]);

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setProductsFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [form, dispatch, defaultValues, filter]);

  return (
    <SearchForm
      title={<TransTitle i18nKey="products" />}
      action={
        <Dropdown
          color="yellow"
          label={
            <>
              <Icon name="add" sx={{ mr: 1 }} />
              <TransButton i18nKey="add" />
            </>
          }
          options={productCategories.map(({ id, description }) => ({
            value: id,
            label: description,
            onClick: () =>
              history.push({
                pathname: '/products/create',
                state: {
                  categoryId: id,
                },
              }),
          }))}
        />
      }
      data-testid={dtid.productsSearchForm}
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                showEmptyOption
                required={false}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField name="code" label={<TransField i18nKey="code" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="description"
                label={<TransField i18nKey="description" />}
                data-testid={dtid.productsSearchDescriptionField}
              />
            </Grid>
            <Grid item xs={1}>
              <ConditionField when="ownerId" is={Boolean}>
                {(ownerId) => (
                  <SelectField
                    name="categoryId"
                    label={<TransField i18nKey="category" />}
                    options={productCategoryOptions}
                    disabled={!ownerId}
                    showEmptyOption
                  />
                )}
              </ConditionField>
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    icon="search"
                    type="submit"
                    data-testid={dtid.productsSearchFormSubmit}
                  >
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
