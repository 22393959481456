import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'store/utils';
import { useAlert } from 'react-alert';
import {
  InventoryModelBucketOption,
  InventoryParametersPayload,
} from 'dto/tariff';
import { TransAlert } from 'i18n/trans/alert';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useSelector } from 'react-redux';
import {
  selectCurrentTariff,
  tariffInventoryParametersSelector,
} from 'features/tariff/tariffSelectors';
import {
  getTariffInventoryParameters,
  updateTariffInventoryParameters,
} from 'features/tariff/tariffActions';
import { fetchInventoryParameters } from 'features/tariff/tariffService';

export const TariffInventoryModel: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentTariff = useSelector(selectCurrentTariff);
  const currentInventoryParameters = useSelector(
    tariffInventoryParametersSelector
  );
  const [inventoryParameterOptions, setInventoryParameterOptions] = useState<
    Array<InventoryModelBucketOption>
  >([]);

  const fetchInventoryParameterOptions = useCallback(async () => {
    setInventoryParameterOptions(
      await fetchInventoryParameters(currentTariff!.owner.id)
    );
  }, [currentTariff]);

  useEffect(() => {
    if (currentTariff?.owner) {
      fetchInventoryParameterOptions();
    }
  }, [currentTariff, fetchInventoryParameterOptions]);

  const onSubmit = useCallback(
    async (values: InventoryParametersPayload) =>
      formSubmit(async () => {
        await dispatch(updateTariffInventoryParameters(values)).unwrap();
        await dispatch(getTariffInventoryParameters(currentTariff!.id));
        alert.success(<TransAlert i18nKey="inventoryParametersUpdated" />);
      }),
    [dispatch, currentTariff, alert]
  );

  const initialValues = useMemo(
    () => ({
      ...(currentInventoryParameters && {
        inventoryModelId: currentInventoryParameters?.inventoryModel?.id,
        inventoryModelBucketId:
          currentInventoryParameters?.inventoryModelBucket?.id,
      }),
    }),
    [currentInventoryParameters]
  );

  const { form, handleSubmit, dirty, submitting, values } =
    useForm<InventoryParametersPayload>({
      initialValues,
      onSubmit,
      subscription: { dirty: true, submitting: true, values: true },
    });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={4} spacing={2}>
          <Grid item xs={1}>
            <SelectField
              label={<TransField i18nKey="inventoryModel" />}
              name="inventoryModelId"
              options={makeClassificationOptions(
                inventoryParameterOptions.map(({ model }) => model)
              )}
              onChange={() => form.change('inventoryModelBucketId')}
              showEmptyOption
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              label={<TransField i18nKey="inventoryBucket" />}
              name="inventoryModelBucketId"
              options={makeClassificationOptions(
                inventoryParameterOptions.find(
                  ({ model }) => model.id === values.inventoryModelId
                )?.buckets || []
              )}
              showEmptyOption
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" flexWrap="nowrap">
              <FormControl label="&nbsp;">
                <Button
                  variant="text"
                  color="primary"
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={!dirty}
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetChanges" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  type="submit"
                  variant="contained"
                  icon="check"
                  disabled={submitting}
                >
                  <TransButton i18nKey="save" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
