export default {
  addCategoryButton: 'add-product-category-button',
  addCategoryDrawer: 'add-product-category-drawer',
  categoryDrawerAddButton: 'add-product-category-drawer-add-button',
  productsSearchForm: 'products-search-form',
  productsSearchFormSubmit: 'products-search-form-submit-button',
  productsSearchResultsTable: 'products-search-results-table',
  productsDetailsAncillaryForm: 'products-details-ancillary-form',
  productsDetailsAdmissionForm: 'products-details-admission-form',
  productsDetailsFeeForm: 'products-details-fee-form',
  productsDetailsReservationForm: 'products-details-reservation-form',
  productsDetailsSupplementForm: 'products-details-supplement-form',
  productsDetailsTravelPassForm: 'products-details-travel-pass-form',
  productsAftersalesRulesetTable: 'products-aftersales-ruleset-table',
  productsAftersalesRulesetAddNew: 'products-aftersales-ruleset-add-new-button',
  productsAftersalesRulesTable: 'products-aftersales-rules-table',
  productsAftersalesRulesAddNew: 'products-aftersales-rules-add-new-button',
  productsLocalizationsAddNew: 'products-localizations-add-new-button',
  productsLocalizationsDeleteSelected:
    'products-localizations-delete-selected-button',
  productsLocalizationsTable: 'products-localizations-table',
  productsLocalizationsDeleteModal:
    'products-localizations-confirm-delete-modal',
  ancillaryTypesAddNew: 'ancillary-types-add-new-button',
  ancillaryTypesDetailsDrawerForm: 'ancillary-types-details-drawer-form',
  ancillaryTypesSubmit: 'ancillary-types-details-form-submit',
  ancillaryTypesResults: 'ancillary-types-results-table',
  ancillaryLocalizationsAddNew:
    'ancillary-types-details-localizations-add-new-button',
  ancillarySubtypesAddNew: 'ancillary-types-details-subtypes-add-new-button',
  ancillaryLocalizationsDeleteSelected:
    'ancillary-types-details-localizations-remove-selected-button',
  ancillarySubtypesDeleteSelected:
    'ancillary-types-details-subtypes-remove-selected-button',
  ancillarySubtypesTable: 'ancillary-types-details-subtypes-table',
  ancillaryLocalizationsTable: 'ancillary-types-details-localizations-table',
  productsSearchDescriptionField: 'products-search-description-field',
  productsLocalizationsDescription: 'products-localizations-description-field',
  productCategoryDescriptionField: 'product-category-description-field',
};
