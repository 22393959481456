import { FC } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { currentProductSelector } from 'features/product/productSelector';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { TaxationRules } from 'routes/products/productAccordion/TaxationRules';
import { useSelector } from 'store/utils';
import { ProductAftersalesRulesets } from 'routes/products/productAccordion/aftersalesRulesets';
import { BundledProducts } from 'routes/products/productAccordion/bundledProducts';
import {
  Product,
  ProductObjectType,
  ProductWithAccordionInformation,
} from 'dto/product';
import { Localizations } from 'routes/products/productAccordion/Localizations';

type TransKeyAndProductField = TransSubtitleKeys &
  keyof ProductWithAccordionInformation<Product>;

const bundledProducts: [TransKeyAndProductField, FC] = [
  'bundledProducts',
  BundledProducts,
];

const aftersalesRuleSets: [TransKeyAndProductField, FC] = [
  'aftersalesRulesets',
  ProductAftersalesRulesets,
];

const taxationRules: [TransKeyAndProductField, FC] = [
  'taxationRules',
  TaxationRules,
];

const localizations: [TransKeyAndProductField, FC] = [
  'localizations',
  Localizations,
];

interface ProductAccordionProps {}

export const ProductAccordion: FC<ProductAccordionProps> = () => {
  const product = useSelector(currentProductSelector);
  const accordionPanels = {
    [ProductObjectType.ADMISSION]: [
      bundledProducts,
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
    [ProductObjectType.ANCILLARY]: [
      bundledProducts,
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
    [ProductObjectType.FEE]: [
      bundledProducts,
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
    [ProductObjectType.RESERVATION]: [
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
    [ProductObjectType.SUPPLEMENT]: [
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
    [ProductObjectType.TRAVEL_PASS]: [
      aftersalesRuleSets,
      taxationRules,
      localizations,
    ],
  };

  if (!product) {
    return null;
  }

  const createDataTestid = (productObjectType: string, panelKey: string) => {
    return productObjectType
      .split('.')[1]
      .toLowerCase()
      .concat('-')
      .concat(panelKey)
      .concat('-drawer-summary');
  };

  return (
    <Accordion controlled={false}>
      {accordionPanels[product.objectType].map(([panelKey, Component]) => (
        <AccordionPanel
          key={panelKey}
          id={panelKey}
          summary={
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              data-testid={createDataTestid(product.objectType, panelKey)}
            >
              <Typography variant="subtitle">
                <TransSubtitle i18nKey={panelKey} />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`(${product[panelKey].length ?? 0})`}
              </Typography>
            </Stack>
          }
          disabled={!Component}
        >
          {product && (Component ? <Component /> : <span />)}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
