import { FC, useEffect } from 'react';
import { Layout } from '@fleet/shared';
import { Route, Switch } from 'react-router-dom';
import { ProductDetails } from 'routes/products/ProductDetails';
import { ProductsTable } from 'routes/products/ProductsTable';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { getProductCategories } from 'features/product/productActions';

interface ProductsProps {}

export const Products: FC<ProductsProps> = () => {
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  useEffect(() => {
    dispatch(getProductCategories(currentBusinessEntityId));
  }, [currentBusinessEntityId, dispatch]);

  return (
    <Layout
      drawer={
        <Switch>
          <Route
            path="/products/:action(create|edit)/:id?"
            component={ProductDetails}
            exact
          />
        </Switch>
      }
    >
      <ProductsTable />
    </Layout>
  );
};
