import { Classifier } from '@fleet/shared/dto/classifier';
import { TaxationRule } from 'dto/taxationRule';
import { AftersalesRuleset } from 'dto/aftersales';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { BaseLocalization } from '@fleet/shared/dto/common';

export interface ProductsFilter extends PaginationParams {
  code: string;
  description: string;
  ownerId: number;
  categoryId: string;
  excludeBundles: boolean;
}

export enum ProductObjectType {
  ADMISSION = 'PRODUCT_CATEGORY.ADMISSION',
  ANCILLARY = 'PRODUCT_CATEGORY.ANCILLARY',
  FEE = 'PRODUCT_CATEGORY.FEE',
  RESERVATION = 'PRODUCT_CATEGORY.RESERVATION',
  SUPPLEMENT = 'PRODUCT_CATEGORY.SUPPLEMENT',
  TRAVEL_PASS = 'PRODUCT_CATEGORY.TRAVEL_PASS',
}

export enum TRAVEL_SERVICE_TYPE {
  JOURNEY_CARD = 'TRAVEL_SERVICE_TYPE.JOURNEY_CARD',
  PERIOD_CARD = 'TRAVEL_SERVICE_TYPE.PERIOD_CARD',
}

interface ProductBase {
  id: string;
  ownerId: number;
  categoryId: string;
  code: string;
  description: string;
  areProductCategoryTaxationRulesUsed: boolean;
  areProductCategoryFullfillmentRulesUsed: boolean;
  aftersalesProfileId: string;
  categoryClassificationId: ProductObjectType;
  subCategoryClassificationId?: string;
}

export interface ProductAdmission extends ProductBase {
  inventoryClasses?: Array<Classifier>;
  reservationOption: Classifier;
  reservationProduct?: Classifier;
  objectType: ProductObjectType.ADMISSION;
}

export interface ProductAdmissionPayload
  extends Omit<
    ProductAdmission,
    'inventoryClasses' | 'reservationOption' | 'reservationProduct'
  > {
  inventoryClassIds?: Array<string> | string;
  reservationOptionId: string;
  reservationProductId?: string;
}

export interface ProductAncillary extends ProductBase {
  inventoryClasses?: Array<Classifier>;
  salesLevel: Classifier;
  ancillaryType?: Classifier;
  ancillarySubtype?: Classifier;
  objectType: ProductObjectType.ANCILLARY;
}

export interface ProductAncillaryPayload extends ProductAncillary {
  salesLevelId: string;
  ancillaryTypeId?: string;
  ancillarySubtypeId?: string;
}

export interface ProductFee extends ProductBase {
  inventoryClasses?: Array<Classifier>;
  objectType: ProductObjectType.FEE;
}

export interface ProductFeePayload
  extends Omit<ProductFee, 'inventoryClasses'> {
  inventoryClassIds?: Array<string> | string;
}

export interface ProductReservation extends ProductBase {
  inventoryClasses?: Array<Classifier>;
  objectType: ProductObjectType.RESERVATION;
}

export interface ProductReservationPayload
  extends Omit<ProductReservation, 'inventoryClasses' | 'reservationOption'> {
  inventoryClassIds?: Array<string> | string;
}

export interface ProductSupplement extends ProductBase {
  objectType: ProductObjectType.SUPPLEMENT;
}

export interface ProductSupplementPayload extends ProductSupplement {}

export interface ProductTravelPass extends ProductBase {
  inventoryClasses?: Array<Classifier>;
  travelPassType: Classifier;
  isForOneWayUsage?: boolean;
  isLimitedOncePerTrip?: boolean;
  isIntermediateStopsCheckInAllowed?: boolean;
  registeringAllowedInSalesChannels?: Array<Classifier>;
  activationEventType: Classifier;
  activationPeriod?: Period;
  validityPeriod: Period;
  numberOfUsages?: number;
  validityPeriodOfOneUsage?: Period;
  objectType: ProductObjectType.TRAVEL_PASS;
}

export interface ProductTravelPassPayload
  extends Omit<
    ProductTravelPass,
    | 'inventoryClasses'
    | 'travelPassType'
    | 'registeringAllowedInSalesChannels'
    | 'activationEventType'
    | 'activationPeriod'
    | 'validityPeriod'
    | 'validityPeriodOfOneUsage'
  > {
  inventoryClassIds?: Array<string> | string;
  travelPassTypeId: string;
  registeringAllowedInSalesChannels?: Array<string>;
  activationEventTypeId: string;
  activationPeriod?: PeriodPayload;
  validityPeriod: PeriodPayload;
  validityPeriodOfOneUsage?: PeriodPayload;
}

export type Product =
  | ProductAdmission
  | ProductAncillary
  | ProductFee
  | ProductReservation
  | ProductSupplement
  | ProductTravelPass;

export type ProductWithInventoryClass =
  | ProductAdmission
  | ProductAncillary
  | ProductFee
  | ProductReservation
  | ProductTravelPass;

export type ProductPayload =
  | ProductAdmissionPayload
  | ProductAncillaryPayload
  | ProductFeePayload
  | ProductReservationPayload
  | ProductSupplementPayload
  | ProductTravelPassPayload;

export interface BundledProduct {
  id: string;
  description: string;
  categoryClassificationId: Classifier;
}

export interface ProductAccordionInformation {
  taxationRules: Array<TaxationRule>;
  bundledProducts: Array<BundledProduct>;
  aftersalesRulesets: Array<AftersalesRuleset>;
  localizations: Array<ProductLocalization>;
}

export type ProductWithAccordionInformation<T extends object> = T &
  ProductAccordionInformation;

export interface ProductCategoryMap {
  categoryClassification: Classifier;
  requiresInventory: boolean;
  subcategoryIds: Array<Classifier>;
  aftersalesActivityIds: Array<string>;
}

export interface Period {
  value: number;
  unit?: Classifier;
}

export interface PeriodPayload {
  value?: number;
  unitId?: string;
}

export interface ProductLocalization extends BaseLocalization {}
