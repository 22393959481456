import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  convertNumberToTimeDuration,
  TableCellEditableTimeDuration,
  TableColumns,
  TimeUnit,
} from '@fleet/shared';
import {
  allowedAftersalesActivityMap,
  DefaultAftersalesRule,
  DefaultAftersalesRulePayload,
} from 'dto/aftersales';
import { TransTableHead } from 'i18n/trans/table';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import {
  createOrUpdateProductAfterSalesRule,
  fetchProductAfterSalesRules,
} from 'features/product/productService';
import { AftersalesRulesTable } from 'routes/products/productAccordion/aftersalesRulesets/AftersalesRulesTable';
import { convertTimeDurationToNumber } from '@fleet/shared/utils/timeDuration';
import { MinAndMaxAmountField } from 'routes/products/productAccordion/aftersalesRulesets/rules/MinAndMaxAmountField';

interface DefaultAftersalesRulesProps {
  productId: string;
  rulesetId: string;
}

type DefaultAftersalesRuleValues = Omit<
  DefaultAftersalesRule,
  'activityTimeRestrictionInMinutes'
> & { timeDurationActivityTimeRestriction: Partial<Record<TimeUnit, number>> };

export const DefaultAftersalesRules: FC<DefaultAftersalesRulesProps> = ({
  productId,
  rulesetId,
}) => {
  const [rules, setRules] = useState<Array<DefaultAftersalesRuleValues>>([]);
  const product = useSelector(currentProductSelector);
  const activityOptions = useClassificationOptions(
    ClassificationGroup.AFTERSALES_ACTIVITY
  );
  const activityTimeRestrictionOptions = useClassificationOptions(
    ClassificationGroup.AFTERSALES_ACTIVITY_TIME_RESTRICTION
  );
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const feeCalculationTypeOptions = useClassificationOptions(
    ClassificationGroup.FEE_CALCULATION_TYPE
  );

  const fetchRules = useCallback(async () => {
    setRules(
      (await fetchProductAfterSalesRules<DefaultAftersalesRule>(rulesetId)).map(
        ({ activityTimeRestrictionInMinutes, ...rest }) => {
          const { days, hours, minutes } = convertNumberToTimeDuration(
            activityTimeRestrictionInMinutes
          );

          return {
            ...rest,
            timeDurationActivityTimeRestriction: {
              days,
              hours,
              minutes,
            },
          };
        }
      )
    );
  }, [rulesetId]);

  useEffect(() => {
    if (productId && rulesetId) {
      fetchRules();
    }
  }, [productId, rulesetId, fetchRules]);

  const columns: TableColumns<DefaultAftersalesRuleValues> = useMemo(
    () => [
      {
        id: 'activityType.id',
        accessor: ({ activityType }) => activityType?.id,
        Header: <TransTableHead i18nKey="activityType" />,
        type: 'select',
        editableProps: {
          options: activityOptions.map(({ value, ...rest }) => ({
            ...rest,
            value,
            isValid:
              allowedAftersalesActivityMap[product!.objectType].includes(value),
          })),
        },
      },
      {
        id: 'activityTimeRestrictionType.id',
        accessor: ({ activityTimeRestrictionType }) =>
          activityTimeRestrictionType?.id,
        Header: <TransTableHead i18nKey="timeRestrictionType" />,
        type: 'select',
        editableProps: {
          options: activityTimeRestrictionOptions,
        },
      },
      {
        accessor: 'timeDurationActivityTimeRestriction',
        Header: <TransTableHead i18nKey="timeRestrictionDuration" />,
        Cell: TableCellEditableTimeDuration,
        editableProps: {
          excludedUnits: [TimeUnit.SECONDS],
        },
        width: 180,
      },
      {
        id: 'feeCalculationType.id',
        accessor: ({ feeCalculationType }) => feeCalculationType?.id,
        Header: <TransTableHead i18nKey="feeValueType" />,
        type: 'select',
        editableProps: {
          options: feeCalculationTypeOptions,
        },
      },
      {
        accessor: 'amount',
        Header: <TransTableHead i18nKey="feeValue" />,
        editableProps: {
          type: 'number',
        },
      },
      {
        accessor: 'minimumAmount',
        Header: <TransTableHead i18nKey="minMaxAmount" />,
        Cell: MinAndMaxAmountField,
      },
      {
        id: 'currency.id',
        accessor: ({ currency }) => currency?.id,
        Header: <TransTableHead i18nKey="currency" />,
        type: 'select',
        editableProps: {
          options: currencyOptions,
        },
      },
    ],
    [
      activityOptions,
      activityTimeRestrictionOptions,
      currencyOptions,
      feeCalculationTypeOptions,
      product,
    ]
  );

  const handleRowUpdate = useCallback(
    async ({
      activityType,
      activityTimeRestrictionType,
      timeDurationActivityTimeRestriction,
      currency,
      feeCalculationType,
      ...rest
    }: DefaultAftersalesRuleValues) => {
      const payload: DefaultAftersalesRulePayload = {
        ...rest,
        activityTypeId: activityType.id,
        objectType: product!.objectType,
        activityTimeRestrictionTypeId: activityTimeRestrictionType.id,
        activityTimeRestrictionInMinutes: convertTimeDurationToNumber(
          timeDurationActivityTimeRestriction
        ),
        currencyId: currency?.id,
        feeCalculationTypeId: feeCalculationType?.id,
      };

      await createOrUpdateProductAfterSalesRule(rulesetId, payload);
      await fetchRules();
    },
    [product, rulesetId, fetchRules]
  );

  return (
    <AftersalesRulesTable
      data={rules}
      columns={columns}
      rulesetId={rulesetId}
      fetchData={fetchRules}
      updateRow={handleRowUpdate}
    />
  );
};
