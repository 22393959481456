import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  getProduct,
  getProductCategoryMapping,
  setCurrentProduct,
} from 'features/product/productActions';
import { AdmissionForm } from 'routes/products/productForms/AdmissionForm';
import { useProductFields } from 'hooks/useProductFormUtilities';
import { AncillaryForm } from 'routes/products/productForms/AncillaryForm';
import { FeeForm } from 'routes/products/productForms/FeeForm';
import { ReservationForm } from 'routes/products/productForms/ReservationForm';
import { SupplementForm } from 'routes/products/productForms/SupplementForm';
import { TravelPassForm } from 'routes/products/productForms/TravelPassForm';
import { ProductObjectType } from 'dto/product';
import { Loadable } from '@fleet/shared';
import { productLoadingSelector } from 'features/loading/loadingSelectors';

interface ProductFormProps {}

export const ProductDetails: FC<ProductFormProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const { categoryId, objectType } = useProductFields();
  const loading = useSelector(productLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const isCreating = useMemo(
    () => action === 'create' && !Boolean(id),
    [action, id]
  );
  const forms = {
    [ProductObjectType.ADMISSION]: <AdmissionForm />,
    [ProductObjectType.ANCILLARY]: <AncillaryForm />,
    [ProductObjectType.FEE]: <FeeForm />,
    [ProductObjectType.RESERVATION]: <ReservationForm />,
    [ProductObjectType.SUPPLEMENT]: <SupplementForm />,
    [ProductObjectType.TRAVEL_PASS]: <TravelPassForm />,
  };

  useEffect(() => {
    dispatch(getProductCategoryMapping());

    if (isEditing && id) {
      dispatch(getProduct(id));
    }

    return () => {
      dispatch(setCurrentProduct(undefined));
    };
  }, [dispatch, id, isEditing]);

  useEffect(() => {
    if (isCreating && !categoryId) {
      history.goBack();
    }
  }, [history, isCreating, categoryId]);

  return (
    <Loadable loading={loading}>{objectType && forms[objectType]}</Loadable>
  );
};
