import { FC, Fragment, useCallback } from 'react';
import {
  Button,
  FieldArray,
  FormControl,
  FormProvider,
  Icon,
  SelectField,
  TextField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { PriceRules } from 'dto/tariff';
import { Divider, Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'react-redux';
import { updateTariffPriceRules } from 'features/tariff/tariffActions';
import { tariffPriceRulesSelector } from 'features/tariff/tariffSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { useDispatch } from 'store/utils';
import { useAlert } from 'react-alert';

export const TariffPriceRules: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentPriceRules = useSelector(tariffPriceRulesSelector);
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );

  const onSubmit = useCallback(
    async (values: PriceRules) =>
      formSubmit(async () => {
        await dispatch(updateTariffPriceRules(values)).unwrap();
        alert.success(<TransAlert i18nKey="coefficientsUpdate" />);
      }),
    [alert, dispatch]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<PriceRules>({
    initialValues: currentPriceRules,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={4} spacing={2}>
          <Grid item xs={1}>
            <TextField
              label={<TransField i18nKey="coefficient" />}
              name="coefficient"
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <FieldArray name="priceRules">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <Fragment key={name}>
                    <Grid item xs={1}>
                      <SelectField
                        label={<TransField i18nKey="currency" />}
                        name={`${name}.currency`}
                        options={currencyOptions}
                        required
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        label={<TransField i18nKey="increment" />}
                        name={`${name}.increment`}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        label={<TransField i18nKey="minValue" />}
                        name={`${name}.minValue`}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Stack direction="row" alignItems="end">
                        <TextField
                          label={<TransField i18nKey="maxValue" />}
                          name={`${name}.maxValue`}
                          type="number"
                        />
                        <Icon
                          size={25}
                          name="trash"
                          sx={{ ml: 2, mb: 0.5 }}
                          onClick={() => fields.remove(index)}
                        />
                      </Stack>
                    </Grid>
                  </Fragment>
                ))}
                <Grid item xs={1}>
                  <Button
                    startIcon={<Icon name="add" />}
                    variant="text"
                    sx={{ pl: 0 }}
                    onClick={() => fields.push({})}
                  >
                    <TransButton i18nKey="addNew" />
                  </Button>
                </Grid>
              </>
            )}
          </FieldArray>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" flexWrap="nowrap">
              <FormControl label="&nbsp;">
                <Button
                  variant="text"
                  color="primary"
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={!dirty}
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetChanges" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  type="submit"
                  variant="contained"
                  icon="check"
                  disabled={submitting}
                >
                  <TransButton i18nKey="save" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
