import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  addProductsInBundle: (
    <Trans
      i18nKey="modal.title.addProductsInBundle"
      defaults="Add products in product bundle"
    />
  ),
  aftersalesRulesDescription: (
    <Trans
      i18nKey="modal.title.aftersalesRulesDescription"
      defaults="(0-1)[Are you sure you want to delete selected aftersales rule? You can't undo this];(2-inf)[Are you sure you want to delete selected aftersales rules? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  ancillaryTypesDeletionDescription: (
    <Trans
      i18nKey="modal.title.ancillaryTypesDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete this ancillary type? You can't undo this];(2-inf)[Are you sure you want to delete this ancillary types? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  deleteAftersalesRules: (
    <Trans
      i18nKey="modal.title.deleteAftersalesRules"
      defaults="Delete aftersales rule"
    />
  ),
  deleteAncillaryTypes: (
    <Trans
      i18nKey="modal.title.deleteAncillaryTypes"
      defaults="Delete ancillary type"
    />
  ),
  deleteLocalizations: (
    <Trans
      i18nKey="modal.title.deleteLocalizations"
      defaults="Delete localization"
    />
  ),
  deleteProduct: (
    <Trans i18nKey="modal.title.deleteProduct" defaults="Delete product" />
  ),
  deleteProductsInBundle: (
    <Trans
      i18nKey="modal.title.deleteProductsInBundle"
      defaults="Delete product in bundle"
    />
  ),
  deleteSubtypes: (
    <Trans i18nKey="modal.title.deleteSubtypes" defaults="Delete subtype" />
  ),
  deleteSupplements: (
    <Trans
      i18nKey="modal.title.deleteSupplements"
      defaults="Delete supplement"
    />
  ),
  deleteTimeDependentConditions: (
    <Trans
      i18nKey="modal.title.deleteTimeDependentConditions"
      defaults="Delete time dependent condition"
    />
  ),
  localizationsDeletionDescription: (
    <Trans
      i18nKey="modal.title.localizationsDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected localization? You can't undo this];(2-inf)[Are you sure you want to delete selected localizations? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  productsInBundleDeletionDescription: (
    <Trans
      i18nKey="modal.title.productsInBundleDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected product in bundle? You can't undo this];(2-inf)[Are you sure you want to delete selected products in bundle? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  subtypesDeletionDescription: (
    <Trans
      i18nKey="modal.title.subtypesDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected subtype? You can't undo this];(2-inf)[Are you sure you want to delete selected subtypes? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  supplementsDeletionDescription: (
    <Trans
      i18nKey="modal.title.supplementsDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected supplement? You can't undo this];(2-inf)[Are you sure you want to delete selected supplements? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  timeDependentConditionsDeletionDescription: (
    <Trans
      i18nKey="modal.title.timeDependentConditionsDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected time dependent condition? You can't undo this];(2-inf)[Are you sure you want to delete selected time dependent conditions? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
});
