import { createSelector, selector } from 'store/utils';

export const selectTariffFilter = selector((state) => state.tariff.filter);
export const tariffFilterSelector = createSelector(selectTariffFilter);

export const selectTariffs = selector((state) => state.tariff.search);
export const tariffsSelector = createSelector(selectTariffs);

export const selectCurrentTariff = selector((state) => state.tariff.current);
export const currentTariffSelector = createSelector(selectCurrentTariff);

export const selectTariffTimeDependentConditions = selector(
  (state) => state.tariff.timeDependentConditions
);
export const tariffTimeDependentConditionsSelector = createSelector(
  selectTariffTimeDependentConditions
);

export const selectTariffSupplements = selector(
  (state) => state.tariff.supplements
);
export const tariffSupplementsSelector = createSelector(
  selectTariffSupplements
);

export const selectTariffPassengerGroup = selector(
  (state) => state.tariff.passengerGroup
);
export const tariffPassengerGroupSelector = createSelector(
  selectTariffPassengerGroup
);

export const selectTariffPriceRules = selector(
  (state) => state.tariff.priceRules
);
export const tariffPriceRulesSelector = createSelector(selectTariffPriceRules);

export const selectTariffSalesRules = selector(
  (state) => state.tariff.salesRules
);
export const tariffSalesRulesSelector = createSelector(selectTariffSalesRules);

export const selectTariffInventoryParameters = selector(
  (state) => state.tariff.inventoryParameters
);
export const tariffInventoryParametersSelector = createSelector(
  selectTariffInventoryParameters
);

export const selectTariffLocalizations = selector(
  (state) => state.tariff.localizations
);
export const tariffLocalizationsSelector = createSelector(
  selectTariffLocalizations
);

const selectTariffRoundTripConditions = selector(
  (state) => state.tariff.roundTripConditions
);

export const tariffRoundTripConditionsSelector = createSelector(
  selectTariffRoundTripConditions
);
