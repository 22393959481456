import type { FC } from 'react';
import { useMemo } from 'react';
import {
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { ProductForm } from 'routes/products/ProductForm';
import {
  Product,
  ProductFee,
  ProductFeePayload,
  ProductObjectType,
} from 'dto/product';
import { useProductSelector } from 'features/product/productSelector';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

const isProductFee = (product?: Product): product is ProductFee =>
  Boolean(product) && product?.objectType === ProductObjectType.FEE;

interface FeeFormProps {}

export const FeeForm: FC<FeeFormProps> = () => {
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const product = useProductSelector(isProductFee);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const inventoryClassIds = useProductFormInventoryClasses(product);
  const businessEntities = useSelector(businessEntitiesSelector);

  const initialValues: Partial<ProductFeePayload> = useMemo(
    () => ({
      ...commonFields,
      ...(product && {
        ...product,
        inventoryClassIds,
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductFeePayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectOwnerField
          businessEntities={businessEntities}
          allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
          disabled
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
      <Grid item xs={1}>
        <InventoryClassField />
      </Grid>
    </ProductForm>
  );
};
