import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentTariffSelector,
  tariffRoundTripConditionsSelector,
} from 'features/tariff/tariffSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Button, FormControl } from '@fleet/shared';
import {
  FormProvider,
  SelectField,
  TextField,
  ToggleButtonGroupField,
  useForm,
} from '@fleet/shared/form';
import { RoundTripConditionsFormValues } from 'dto/roundTripConditions';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  deleteTariffRoundTripConditions,
  getTariffRoundTripConditions,
  updateTariffRoundTripConditions,
} from 'features/tariff/tariffActions';
import { useWeekdaysOptions } from '@fleet/shared';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import _isEmpty from 'lodash/isEmpty';

export const RoundTripConditions: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const roundTripConditions = useSelector(tariffRoundTripConditionsSelector);
  const currentTariff = useSelector(currentTariffSelector);
  const journeyDirectionOptions = useClassificationOptions(
    ClassificationGroup.JOURNEY_DIRECTION
  );
  const weekdaysOptions = useWeekdaysOptions();

  const onSubmit = useCallback(
    async (values: RoundTripConditionsFormValues) => {
      if (!currentTariff?.id) {
        alert.error(<TransAlert i18nKey="tariffRTCUpdateFail" />);
        return;
      }
      await dispatch(
        updateTariffRoundTripConditions({
          ...values,
          tariffId: currentTariff.id,
        })
      ).unwrap();

      alert.success(<TransAlert i18nKey="tariffRTCUpdated" />);
    },
    [alert, currentTariff, dispatch]
  );

  const { form, handleSubmit, dirty, submitting } =
    useForm<RoundTripConditionsFormValues>({
      initialValues: {
        ...roundTripConditions,
        journeyDirectionId: roundTripConditions?.journeyDirection?.id,
      },
      onSubmit,
      subscription: {
        dirty: true,
        submitting: true,
      },
    });

  const handleClear = useCallback(async () => {
    if (!currentTariff?.id) {
      alert.error(<TransAlert i18nKey="clearFailed" />);
      return;
    }
    await dispatch(deleteTariffRoundTripConditions(currentTariff.id)).unwrap();

    alert.success(<TransAlert i18nKey="tariffRTCRemoved" />);
    await dispatch(getTariffRoundTripConditions(currentTariff.id));
  }, [alert, dispatch, currentTariff?.id]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} rowSpacing={4}>
          <Grid item xs={1}>
            <SelectField
              name="journeyDirectionId"
              options={journeyDirectionOptions}
              label={<TransField i18nKey="journeyDirection" />}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="stayoverDaysFrom"
              label={<TransField i18nKey="stayoverPeriodInDaysFrom" />}
              type="number"
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="stayoverDaysTo"
              label={<TransField i18nKey="stayoverPeriodInDaysTo" />}
              type="number"
            />
          </Grid>
          <Grid item xs={1}>
            <ToggleButtonGroupField
              name="stayoverDaysOfTheWeek"
              options={weekdaysOptions}
              fullWidth
              multiple
              selectAllButton
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" flexWrap="nowrap">
              <FormControl label="&nbsp;">
                <Button
                  variant="text"
                  color="primary"
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={handleClear}
                  disabled={_isEmpty(roundTripConditions)}
                >
                  <TransButton i18nKey="removeConditions" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  variant="text"
                  color="primary"
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={!dirty}
                  onClick={() => form.reset()}
                >
                  <TransButton i18nKey="resetChanges" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  type="submit"
                  variant="contained"
                  icon="check"
                  disabled={submitting}
                >
                  <TransButton i18nKey="save" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
