import { FC } from 'react';
import { useWeekdaysOptions } from '@fleet/shared';
import { Grid } from '@mui/material';
import { ToggleButtonGroupField } from '@fleet/shared/form';

export const TravelDaysOfTheWeek: FC = () => {
  const weekdaysOptions = useWeekdaysOptions();
  return (
    <Grid item xs={1}>
      <ToggleButtonGroupField
        name="travelDaysOfTheWeek"
        options={weekdaysOptions}
        selectAllButton
        multiple
        fullWidth
      />
    </Grid>
  );
};
