import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  activityType: (
    <Trans i18nKey="table.head.activityType" defaults="Activity type" />
  ),
  afterSalesRulesetTemplate: (
    <Trans
      i18nKey="table.head.afterSalesRulesetTemplate"
      defaults="Aftersale ruleset template"
    />
  ),
  carriers: <Trans i18nKey="table.head.carriers" defaults="Carriers" />,
  category: <Trans i18nKey="table.head.category" defaults="Category" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  currency: <Trans i18nKey="table.head.currency" defaults="Currency" />,
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  fareCategory: (
    <Trans i18nKey="table.head.fareCategory" defaults="Fare category" />
  ),
  feeAppliedForDownsell: (
    <Trans
      i18nKey="table.head.feeAppliedForDownsell"
      defaults="Fee applied only for downsell"
    />
  ),
  feeType: <Trans i18nKey="table.head.feeType" defaults="Fee type" />,
  feeValue: <Trans i18nKey="table.head.feeValue" defaults="Fee value" />,
  feeValueType: (
    <Trans i18nKey="table.head.feeValueType" defaults="Fee value type" />
  ),
  includedProducts: (
    <Trans i18nKey="table.head.includedProducts" defaults="Included products" />
  ),
  inventoryClasses: (
    <Trans i18nKey="table.head.inventoryClasses" defaults="Class" />
  ),
  isPartialRefundAllowed: (
    <Trans
      i18nKey="table.head.isPartialRefundAllowed"
      defaults="Is partial refund allowed"
    />
  ),
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  localizations: (
    <Trans i18nKey="table.head.localizations" defaults="Localizations" />
  ),
  maxCount: <Trans i18nKey="table.head.maxCount" defaults="Max count" />,
  minCount: <Trans i18nKey="table.head.minCount" defaults="Min count" />,
  minMaxAmount: (
    <Trans i18nKey="table.head.minMaxAmount" defaults="Min/max amount" />
  ),
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  order: <Trans i18nKey="table.head.order" defaults="Order" />,
  organization: (
    <Trans i18nKey="table.head.organization" defaults="Organization" />
  ),
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  passengerType: (
    <Trans i18nKey="table.head.passengerType" defaults="Passenger type" />
  ),
  percentageKeptByClient: (
    <Trans
      i18nKey="table.head.percentageKeptByClient"
      defaults="Percentage kept by client"
    />
  ),
  primaryFareType: (
    <Trans i18nKey="table.head.primaryFareType" defaults="Primary fare type" />
  ),
  product: <Trans i18nKey="table.head.product" defaults="Product" />,
  productName: (
    <Trans i18nKey="table.head.productName" defaults="Product name" />
  ),
  salesChannel: (
    <Trans i18nKey="table.head.salesChannel" defaults="Sales channel" />
  ),
  salesDateFrom: (
    <Trans i18nKey="table.head.salesDateFrom" defaults="Sales date from" />
  ),
  salesDateTo: (
    <Trans i18nKey="table.head.salesDateTo" defaults="Sales date to" />
  ),
  salesPeriod: (
    <Trans i18nKey="table.head.salesPeriod" defaults="Sales period" />
  ),
  serviceCodes: <Trans i18nKey="table.head.serviceCodes" defaults="Services" />,
  shortDescription: (
    <Trans i18nKey="table.head.shortDescription" defaults="Short description" />
  ),
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house number"
    />
  ),
  tax: <Trans i18nKey="table.head.tax" defaults="Tax %" />,
  taxType: <Trans i18nKey="table.head.taxType" defaults="Tax Type" />,
  timeRestrictionDuration: (
    <Trans
      i18nKey="table.head.timeRestrictionDuration"
      defaults="Time restriction duration"
    />
  ),
  timeRestrictionType: (
    <Trans
      i18nKey="table.head.timeRestrictionType"
      defaults="Time restriction type"
    />
  ),
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
  zoneEnd: <Trans i18nKey="table.head.zoneEnd" defaults="Zone end" />,
  zoneRule: <Trans i18nKey="table.head.zoneRule" defaults="Zone rule" />,
  zoneStart: <Trans i18nKey="table.head.zoneStart" defaults="Zone start" />,
});
