import {
  copyTariffById,
  createOrUpdateTariffPassengerGroup,
  createOrUpdateTariffTimeDependentConditions,
  createTariff,
  deleteTariffLocalizations,
  deleteTariffPassengerGroup,
  deleteTariffRoundTripConditions,
  deleteTariffTimeDependentConditions,
  getTariffById,
  getTariffInventoryParameters,
  getTariffLocalizations,
  getTariffPassengerGroup,
  getTariffPriceRules,
  getTariffRoundTripConditions,
  getTariffs,
  getTariffSalesRules,
  getTariffSupplements,
  getTariffTimeDependentConditions,
  updateOrCreateTariffLocalization,
  updateTariff,
  updateTariffInventoryParameters,
  updateTariffPriceRules,
  updateTariffRoundTripConditions,
  updateTariffSalesRules,
} from 'features/tariff/tariffActions';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { createLoadingSelector } from 'store/utils';
import {
  deleteProductLocalizations,
  getProduct,
  getProductCategoryMapping,
  getProducts,
  updateBundledProducts,
  updateOrCreateProductLocalization,
} from 'features/product/productActions';

import {
  getProductCategoryById,
  getProductCategoryList,
} from 'features/productCategory/productCategoryActions';
import {
  createAncillaryType,
  deleteAncillaryTypeLocalizations,
  deleteAncillaryTypes,
  deleteAncillaryTypeSubtypeLocalizations,
  deleteAncillaryTypeSubtypes,
  getAncillaryType,
  getAncillaryTypes,
  getAncillaryTypeSubtypes,
  getFullAncillaryType,
  reorderAncillaryTypes,
  reorderAncillaryTypeSubtypes,
  updateAncillaryType,
  updateOrCreateAncillaryTypeLocalizations,
  updateOrCreateAncillaryTypeSubtypeLocalizations,
  updateOrCreateAncillaryTypeSubtypes,
} from 'features/ancillaryTypes/ancillaryTypesActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const productLoadingSelector = createLoadingSelector(
  getProduct,
  getProductCategoryMapping,
  updateBundledProducts,
  updateOrCreateProductLocalization,
  deleteProductLocalizations
);

export const productsTableLoadingSelector = createLoadingSelector(getProducts);

export const tariffListLoadingSelector = createLoadingSelector(
  getTariffs,
  getTariffById,
  createTariff,
  updateTariff,
  copyTariffById,
  getTariffTimeDependentConditions,
  createOrUpdateTariffTimeDependentConditions,
  deleteTariffTimeDependentConditions,
  getTariffSupplements,
  getTariffPassengerGroup,
  createOrUpdateTariffPassengerGroup,
  deleteTariffPassengerGroup,
  getTariffPriceRules,
  updateTariffPriceRules,
  getTariffSalesRules,
  updateTariffSalesRules,
  getTariffInventoryParameters,
  updateTariffInventoryParameters,
  getTariffLocalizations,
  updateOrCreateTariffLocalization,
  deleteTariffLocalizations,
  updateTariffRoundTripConditions,
  getTariffRoundTripConditions,
  deleteTariffRoundTripConditions
);
export const tariffLoadingSelector = createLoadingSelector(getTariffById);

export const productCategoryLoadingSelector = createLoadingSelector(
  getProductCategoryList,
  getProductCategoryById
);

export const ancillaryTypesLoadingSelector = createLoadingSelector(
  getAncillaryTypes,
  getAncillaryType,
  getAncillaryTypeSubtypes,
  reorderAncillaryTypes,
  deleteAncillaryTypes,
  createAncillaryType,
  updateAncillaryType,
  updateOrCreateAncillaryTypeLocalizations,
  deleteAncillaryTypeLocalizations,
  updateOrCreateAncillaryTypeSubtypes,
  deleteAncillaryTypeSubtypes,
  reorderAncillaryTypeSubtypes,
  updateOrCreateAncillaryTypeSubtypeLocalizations,
  deleteAncillaryTypeSubtypeLocalizations
);
export const ancillaryTypeLoadingSelector = createLoadingSelector(
  getFullAncillaryType,
  getAncillaryType,
  getAncillaryTypeSubtypes,
  createAncillaryType,
  updateAncillaryType,
  updateOrCreateAncillaryTypeLocalizations,
  deleteAncillaryTypeLocalizations,
  updateOrCreateAncillaryTypeSubtypes,
  deleteAncillaryTypeSubtypes,
  reorderAncillaryTypeSubtypes,
  updateOrCreateAncillaryTypeSubtypeLocalizations,
  deleteAncillaryTypeSubtypeLocalizations
);
