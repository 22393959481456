import qs from 'qs';
import {
  InventoryModelBucketOption,
  PassengerTypes,
  RetailerContract,
} from 'dto/tariff';
import { PassengerTypeDiscount, PromotionalDiscount } from 'dto/discount';
import { Pagination } from '@fleet/shared/dto/pagination';
import { SearchQueryStopsParams, Stop } from 'dto/stop';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { Product } from 'dto/product';

export const fetchRetailerContracts = async (carrierId: number) =>
  (
    await api.get<{ items: Array<RetailerContract> }>(
      `/retailer-contracts${qs.stringify(
        { carrierId, includeInactive: true },
        {
          addQueryPrefix: true,
        }
      )}`,
      { baseURL: process.env.REACT_APP_API_RM }
    )
  ).data.items;

export const fetchInventoryParameters = async (ownerId: number) =>
  (
    await api.get<{ items: Array<InventoryModelBucketOption> }>(
      `/models/buckets${qs.stringify(
        { ownerId },
        {
          addQueryPrefix: true,
        }
      )}`,
      { baseURL: process.env.REACT_APP_API_IM }
    )
  ).data.items;

export const fetchProducts = async (ownerId: number) => {
  const products = (
    await api.get<Pagination<Product>>(
      `/products${qs.stringify(
        { ownerId, limit: 2000000000 },
        {
          addQueryPrefix: true,
        }
      )}`
    )
  ).data.items;

  return products.map(({ description, ...rest }) => ({
    name: description,
    ...rest,
  }));
};

export const fetchTariffPassengerTypes = async (ownerId: number) =>
  (
    await api.get<{ items: Array<PassengerTypes> }>(
      `/passenger-types${qs.stringify({ ownerId }, { addQueryPrefix: true })}`
    )
  ).data.items;

export const fetchTariffPassengerTypeDiscounts = async (
  ownerId: number
): Promise<Array<PassengerTypeDiscount>> =>
  (
    await api.get<{ items: Array<PassengerTypeDiscount> }>(
      `/passenger-type-discounts${qs.stringify(
        { ownerId, limit: 2000000000 },
        { addQueryPrefix: true }
      )}`,
      { baseURL: process.env.REACT_APP_API_DM }
    )
  ).data.items;

export const fetchStops = async (filter: SearchQueryStopsParams) =>
  (
    await api.get<Pagination<Stop>>(
      `/stops${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data;

export const fetchPromotionalDiscounts = async (ownerId: number) =>
  (
    await api.get<{ items: Array<PromotionalDiscount> }>(
      `/promotional-discounts${qs.stringify(
        {
          ownerId,
          limit: 9999,
          isPublic: false,
        },
        {
          addQueryPrefix: true,
        }
      )}`,
      { baseURL: process.env.REACT_APP_API_DM }
    )
  ).data.items;

export const fetchCarriers = async (ownerId: number) =>
  (
    await api.get<{ items: Array<Classifier> }>(
      `/business-entities/${ownerId}/carriers`
    )
  ).data.items;
