import { FC, useEffect, useMemo } from 'react';
import { Accordion, AccordionPanel, useField } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentTariffSelector,
  tariffLocalizationsSelector,
  tariffSupplementsSelector,
  tariffTimeDependentConditionsSelector,
} from 'features/tariff/tariffSelectors';
import { TariffTimeDependentConditions } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TariffTimeDependentConditions';
import {
  getTariffInventoryParameters,
  getTariffLocalizations,
  getTariffPriceRules,
  getTariffRoundTripConditions,
  getTariffSalesRules,
  getTariffSupplements,
  getTariffTimeDependentConditions,
} from 'features/tariff/tariffActions';
import { TariffSupplements } from 'routes/tariff/tariffFormAccordion/supplements/TariffSupplements';
import { TariffPriceRules } from 'routes/tariff/tariffFormAccordion/TariffPriceRules';
import { TariffSalesChannelsAndRetailers } from 'routes/tariff/tariffFormAccordion/TariffSalesChannelsAndRetailers';
import { TariffInventoryModel } from 'routes/tariff/tariffFormAccordion/TariffInventoryModel';
import { TariffLocalizations } from 'routes/tariff/tariffFormAccordion/TariffLocalizations';
import { RoundTripConditions } from 'routes/tariff/tariffFormAccordion/RoundTripConditions';
import { ProductObjectType } from 'dto/product';

interface TariffFormAccordionProps {}

export const TariffFormAccordion: FC<TariffFormAccordionProps> = () => {
  const dispatch = useDispatch();
  const tariff = useSelector(currentTariffSelector);
  const tariffTimeDependentConditions = useSelector(
    tariffTimeDependentConditionsSelector
  );
  const tariffSupplements = useSelector(tariffSupplementsSelector);
  const tariffLocalizations = useSelector(tariffLocalizationsSelector);
  const {
    input: { value: categoryClassificationId },
  } = useField('categoryClassificationId');

  const panels: Array<{
    key: TransSubtitleKeys;
    component: FC;
    quantity?: number;
    allowedCategoryClassificationId?: ProductObjectType;
  }> = useMemo(
    () => [
      { key: 'coefficients', component: TariffPriceRules },
      {
        key: 'roundTripConditions',
        component: RoundTripConditions,
        allowedCategoryClassificationId: ProductObjectType.ADMISSION,
      },
      {
        key: 'inventoryModel',
        component: TariffInventoryModel,
        allowedCategoryClassificationId: ProductObjectType.ADMISSION,
      },
      // { translationKey: 'passengerGroup', component: TariffPassengerGroup },
      {
        key: 'timeDependentConditions',
        component: TariffTimeDependentConditions,
        quantity: tariffTimeDependentConditions.length,
      },
      {
        key: 'salesChannelsAndRetailers',
        component: TariffSalesChannelsAndRetailers,
      },
      {
        key: 'supplements',
        component: TariffSupplements,
        quantity: tariffSupplements.length,
        allowedCategoryClassificationId: ProductObjectType.ADMISSION,
      },
      {
        key: 'localizations',
        component: TariffLocalizations,
        quantity: tariffLocalizations.length,
      },
    ],
    [
      tariffLocalizations.length,
      tariffSupplements.length,
      tariffTimeDependentConditions.length,
    ]
  );

  useEffect(() => {
    if (tariff) {
      dispatch(getTariffTimeDependentConditions(tariff.id));
      dispatch(getTariffSupplements(tariff.id));
      // dispatch(getTariffPassengerGroup(tariff.id));
      dispatch(getTariffPriceRules(tariff.id));
      dispatch(getTariffSalesRules(tariff.id));
      dispatch(getTariffInventoryParameters(tariff.id));
      dispatch(getTariffLocalizations(tariff.id));
      dispatch(getTariffRoundTripConditions(tariff.id));
    }
  }, [tariff, dispatch]);

  if (!tariff || !categoryClassificationId) {
    return null;
  }

  return (
    <Accordion controlled={false}>
      {panels
        .filter(
          ({ allowedCategoryClassificationId }) =>
            !allowedCategoryClassificationId ||
            allowedCategoryClassificationId === categoryClassificationId
        )
        .map(({ key, component: Component, quantity }) => (
          <AccordionPanel
            key={key}
            id={key}
            summary={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey={key} />
                </Typography>
                {Number.isInteger(quantity) && (
                  <Typography variant="body2" color="text.secondary">
                    {`(${quantity})`}
                  </Typography>
                )}
              </Stack>
            }
            disabled={!Component}
          >
            {Component ? <Component /> : <span />}
          </AccordionPanel>
        ))}
    </Accordion>
  );
};
