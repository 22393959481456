import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  ConditionField,
  FormControl,
  RadioGroupField,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
  RadioGroupFieldProps,
} from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { ProductForm } from 'routes/products/ProductForm';
import { useProductSelector } from 'features/product/productSelector';
import {
  Product,
  ProductObjectType,
  ProductTravelPass,
  ProductTravelPassPayload,
  TRAVEL_SERVICE_TYPE,
} from 'dto/product';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

const isProductTravelPass = (product?: Product): product is ProductTravelPass =>
  Boolean(product) && product?.objectType === ProductObjectType.TRAVEL_PASS;

interface TravelPassFormProps {}

export const TravelPassForm: FC<TravelPassFormProps> = () => {
  const travelServiceMeasurementUnitOptions = useClassificationOptions(
    ClassificationGroup.TRAVEL_SERVICE_MEASUREMENT_UNIT
  );
  const travelPassTypeOptions = useClassificationOptions(
    ClassificationGroup.TRAVEL_SERVICE_TYPE
  );
  const salesChannelOptions = useClassificationOptions(
    ClassificationGroup.SALES_CHANNEL
  );
  const activationEventTypeOptions = useClassificationOptions(
    ClassificationGroup.TRAVEL_SERVICE_ACTIVATION_EVENT
  );
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const product = useProductSelector(isProductTravelPass);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const businessEntities = useSelector(businessEntitiesSelector);
  const inventoryClassIds = useProductFormInventoryClasses(product);

  const initialValues: Partial<ProductTravelPassPayload> = useMemo(
    () => ({
      ...commonFields,
      isIntermediateStopsCheckInAllowed: false,
      isLimitedOncePerTrip: false,
      isOriginDestinationSpecific: false,
      isForOneWayUsage: false,
      ...(product && {
        ...product,
        inventoryClassIds,
        travelPassTypeId: product.travelPassType?.id,
        registeringAllowedInSalesChannels:
          product.registeringAllowedInSalesChannels?.map(({ id }) => id),
        activationEventTypeId: product.activationEventType.id,
        activationPeriod: {
          value: product.activationPeriod?.value,
          unitId: product.activationPeriod?.unit?.id,
        },
        validityPeriod: {
          value: product.validityPeriod?.value,
          unitId: product.validityPeriod?.unit?.id,
        },
        validityPeriodOfOneUsage: {
          value: product.validityPeriodOfOneUsage?.value,
          unitId: product.validityPeriodOfOneUsage?.unit?.id,
        },
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductTravelPassPayload>({
    initialValues,
    onSubmit,
    subscription: {
      dirty: true,
      submitting: true,
      values: true,
      errors: true,
      touched: true,
    },
  });

  const handleActivationEventTypeChange = useCallback(
    (value: string) => {
      if (value === 'TRAVEL_SERVICE_ACTIVATION_EVENT.PURCHASE') {
        formProps.form.change('activationPeriod');
      }
    },
    [formProps.form]
  );

  const hasValidityPeriodError = useMemo(
    () =>
      Boolean(
        (formProps.touched?.['validityPeriod.value'] &&
          formProps.errors?.validityPeriod?.value) ||
          (formProps.touched?.['validityPeriod.unitId'] &&
            formProps.errors?.validityPeriod?.unitId)
      ),
    [
      formProps.errors?.validityPeriod?.unitId,
      formProps.errors?.validityPeriod?.value,
      formProps.touched,
    ]
  );

  const hasValidityOfOneUsageError = useMemo(
    () =>
      Boolean(
        (formProps.touched?.['validityPeriodOfOneUsage.value'] &&
          formProps.errors?.validityPeriodOfOneUsage?.value) ||
          (formProps.touched?.['validityPeriodOfOneUsage.unitId'] &&
            formProps.errors?.validityPeriodOfOneUsage?.unitId)
      ),
    [
      formProps.errors?.validityPeriodOfOneUsage?.unitId,
      formProps.errors?.validityPeriodOfOneUsage?.value,
      formProps.touched,
    ]
  );

  useEffect(() => {
    // TODO, app crashes here on linked @fleet/shared
    // formProps.form?.resetFieldState('numberOfUsages');
  }, [formProps.form, formProps.values.travelPassTypeId]);

  const handleChangeTravelPassTypeId = useCallback<
    Required<RadioGroupFieldProps<string>>['onChange']
  >(
    (event) => {
      if (event.target.value !== TRAVEL_SERVICE_TYPE.JOURNEY_CARD)
        formProps.form.change('isLimitedOncePerTrip', false);
    },
    [formProps.form]
  );

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectOwnerField
          businessEntities={businessEntities}
          allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
          disabled
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
      <Grid item xs={1}>
        <InventoryClassField />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="registeringAllowedInSalesChannels"
          label={<TransField i18nKey="registeringAllowed" />}
          options={salesChannelOptions}
          multiple
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={1}>
        <RadioGroupField
          name="travelPassTypeId"
          label={<TransField i18nKey="passType" />}
          options={travelPassTypeOptions}
          required
          inline
          onChange={handleChangeTravelPassTypeId}
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          name="isForOneWayUsage"
          label={<TransField i18nKey="usageType" />}
          options={[
            { label: <TransField i18nKey="usageType.ONE_WAY" />, value: true },
            {
              label: <TransField i18nKey="usageType.ROUND_TRIP" />,
              value: false,
            },
          ]}
          inline
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          name="isIntermediateStopsCheckInAllowed"
          label={<TransField i18nKey="isIntermediateStopsCheckInAllowed" />}
          options="BOOL_ONLY"
          inline
        />
      </Grid>

      <Grid item xs={1}>
        <ConditionField
          when="travelPassTypeId"
          is={TRAVEL_SERVICE_TYPE.JOURNEY_CARD}
        >
          {(result) => (
            <RadioGroupField
              name="isLimitedOncePerTrip"
              label={<TransField i18nKey="isLimitedOncePerTrip" />}
              options="BOOL_ONLY"
              inline
              disabled={!result}
            />
          )}
        </ConditionField>
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          name="isOriginDestinationSpecific"
          label={<TransField i18nKey="isOriginDestinationSpecific" />}
          options="BOOL_ONLY"
          inline
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="minimumTimeBetweenDeparturesInMinutes"
          label={<TransField i18nKey="minimumTimeBetweenDeparturesInMinutes" />}
          type="number"
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="departureRestrictionPeriodInHours"
          label={<TransField i18nKey="departureRestrictionPeriodInHours" />}
          type="number"
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="numberOfDeparturesAllowedInRestrictionPeriod"
          label={
            <TransField i18nKey="numberOfDeparturesAllowedInRestrictionPeriod" />
          }
          type="number"
        />
      </Grid>

      <Grid item xs={1}>
        <SelectField
          name="activationEventTypeId"
          label={<TransField i18nKey="activationEventType" />}
          options={activationEventTypeOptions}
          onChange={handleActivationEventTypeChange}
          required
        />
      </Grid>
      <ConditionField
        when="activationEventTypeId"
        is={activationEventTypeOptions
          .filter(
            ({ value }) => value !== 'TRAVEL_SERVICE_ACTIVATION_EVENT.PURCHASE'
          )
          .map(({ value }) => value)}
      >
        <Grid item xs={1}>
          <FormControl label={<TransField i18nKey="activationPeriod" />}>
            <Grid container columns={2} spacing={2} alignItems="flex-start">
              <Grid item xs={1}>
                <TextField name="activationPeriod.value" type="number" />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="activationPeriod.unitId"
                  options={travelServiceMeasurementUnitOptions}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </ConditionField>
      <Grid item xs={1}>
        <FormControl
          label={<TransField i18nKey="validityPeriod" />}
          required
          error={hasValidityPeriodError}
        >
          <Grid container columns={2} spacing={2} alignItems="flex-start">
            <Grid item xs={1}>
              <TextField name="validityPeriod.value" type="number" required />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="validityPeriod.unitId"
                options={travelServiceMeasurementUnitOptions}
                required
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={<TransField i18nKey="numberOfUsages" />}
          name="numberOfUsages"
          type="number"
          required={
            formProps.values.travelPassTypeId ===
            TRAVEL_SERVICE_TYPE.JOURNEY_CARD
          }
        />
      </Grid>
      <ConditionField
        when="travelPassTypeId"
        is={TRAVEL_SERVICE_TYPE.JOURNEY_CARD}
      >
        <Grid item xs={1}>
          <FormControl
            label={<TransField i18nKey="validityPeriodOfOneUsage" />}
            required
            error={hasValidityOfOneUsageError}
          >
            <Grid container columns={2} spacing={2} alignItems="flex-start">
              <Grid item xs={1}>
                <TextField
                  name="validityPeriodOfOneUsage.value"
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="validityPeriodOfOneUsage.unitId"
                  options={travelServiceMeasurementUnitOptions}
                  required
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </ConditionField>
    </ProductForm>
  );
};
