import { Layout } from '@fleet/shared';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TariffForm } from 'routes/tariff/TariffForm';
import { TariffTable } from 'routes/tariff/TariffTable';

export const Tariff: FC = () => (
  <Layout
    drawer={
      <Switch>
        <Route
          path="/tariff/:action(create|edit)/:id?"
          component={TariffForm}
          exact
        />
      </Switch>
    }
  >
    <TariffTable />
  </Layout>
);
