import { createAction } from '@reduxjs/toolkit';
import type {
  BundledProduct,
  ProductCategoryMap,
  ProductLocalization,
  ProductsFilter,
  ProductWithAccordionInformation,
} from 'dto/product';
import { Product, ProductPayload } from 'dto/product';
import {
  fetchProductCategories,
  fetchProducts,
} from 'features/product/productService';
import { createAsyncThunk } from 'store/utils';
import type { Pagination } from '@fleet/shared/dto/pagination';
import { TaxationRule } from 'dto/taxationRule';
import { AftersalesRuleset } from 'dto/aftersales';
import { ProductCategory } from 'dto/productCategory';
import { api } from '@fleet/shared';
import qs from 'qs';

export const getProductCategories = createAsyncThunk<
  Array<ProductCategory>,
  number | undefined
>('product/getProductCategories', fetchProductCategories);

export const getProductCategoryMapping = createAsyncThunk<
  Array<ProductCategoryMap>
>(
  'product/getProductCategoryMapping',
  async () => (await api.get(`/product-categories/mappings`)).data.items
);

export const setProductsFilter = createAction<Partial<ProductsFilter>>(
  'product/setProductsFilter'
);

export const clearProducts = createAction('product/clearProducts');

export const getProducts = createAsyncThunk<
  Pagination<Product>,
  Partial<ProductsFilter> | undefined
>('product/getProducts', async (values, { dispatch, getState }) => {
  values && dispatch(setProductsFilter(values));
  const { filter } = getState().product;
  return await fetchProducts(filter);
});

export const setCurrentProduct = createAction<
  ProductWithAccordionInformation<Product> | undefined
>('product/setCurrentProduct');

export const getProduct = createAsyncThunk<
  ProductWithAccordionInformation<Product>,
  string
>('product/getProduct', async (id) => {
  const [
    product,
    taxationRules,
    aftersalesRulesets,
    bundledProducts,
    localizations,
  ] = await Promise.all([
    api.get<Product>(`/products/${id}`),
    api.get<{ items: Array<TaxationRule> }>(`/products/${id}/taxation-rules`),
    api.get<{ items: Array<AftersalesRuleset> }>(
      `/products/${id}/aftersales/rulesets`
    ),
    api.get<{ products: Array<BundledProduct> }>(`/products/${id}/bundle`),
    api.get<{ items: Array<ProductLocalization> }>(
      `/products/${id}/localizations`
    ),
  ]);

  return {
    ...product.data,
    taxationRules: taxationRules.data.items.map((rule) => ({
      ...rule,
      percentage: parseFloat((rule.percentage * 100).toPrecision(3)),
    })),
    aftersalesRulesets: aftersalesRulesets.data.items,
    bundledProducts: bundledProducts.data.products,
    localizations: localizations.data.items,
  };
});

export const updateBundledProducts = createAsyncThunk<
  Array<BundledProduct>,
  { id: string; productIds: Array<string> }
>('product/updateBundledProducts', async ({ id, productIds }) => {
  return (
    await api.put<{ products: Array<BundledProduct> }>(
      `/products/${id}/bundle`,
      { productIds }
    )
  ).data.products;
});

export const createProduct = createAsyncThunk<Product, ProductPayload>(
  'product/createProduct',
  async (payload) => (await api.post(`/products`, payload)).data
);

export const updateProduct = createAsyncThunk<Product, ProductPayload>(
  'product/updateProduct',
  async (payload) =>
    (await api.put<Product>(`/products/${payload.id}`, payload)).data
);

export const updateOrCreateProductLocalization = createAsyncThunk<
  void,
  { productId: string; languageId: string; description: string }
>(
  'product/updateOrCreateProductLocalization',
  async ({ productId, languageId, description }, { dispatch }) => {
    await api.put<ProductLocalization>(
      `/products/${productId}/localizations/${languageId}`,
      { description }
    );

    await dispatch(getProduct(productId));
  }
);

export const deleteProductLocalizations = createAsyncThunk<
  void,
  {
    id: string;
    languageIds: Array<string>;
  }
>(
  'product/deleteProductLocalizations',
  async ({ id: productId, languageIds }) =>
    await api.delete(
      `/products/${productId}/localizations${qs.stringify(
        { languageIds },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    )
);
