import { useCallback } from 'react';
import {
  Card,
  ConfirmDeleteModal,
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTableControls,
  useModal,
} from '@fleet/shared';
import { Button, CardContent, Stack } from '@mui/material';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { deleteProductAfterSalesRule } from 'features/product/productService';
import { AftersalesRuleBase } from 'dto/aftersales';
import { TransModal } from 'i18n/trans/modal';
import { TransField } from 'i18n/trans/field';
import dtid from 'helpers/dtid';

interface AftersalesRulesTableProps<T extends AftersalesRuleBase> {
  data: Array<T>;
  columns: TableColumns<T>;
  rulesetId: string;
  fetchData: () => void;
  updateRow: (d: T) => void;
}

export const AftersalesRulesTable = <T extends AftersalesRuleBase>({
  data,
  columns,
  rulesetId,
  fetchData,
  updateRow,
}: AftersalesRulesTableProps<T>) => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const { form } = useForm<{
    rows: Array<T>;
  }>({
    initialValues: {
      rows: data,
    },
    subscription: { values: true },
  });

  const table = useFormTable<T>(
    {
      data,
      columns,
      form,
      autoResetExpanded: false,
      onRowUpdate: updateRow,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const handleRowsDeleted = useCallback(
    async (values: Array<T>) => {
      await Promise.all(
        values.map(({ id }) => deleteProductAfterSalesRule(rulesetId, id))
      );

      onClose();

      fetchData();
    },
    [fetchData, onClose, rulesetId]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  return (
    <Card sx={{ mb: 1, mt: 1 }}>
      <CardContent>
        <FormProvider form={form}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ mb: 1 }}
          >
            <Button
              startIcon={<Icon name="delete" />}
              onClick={onOpen}
              variant="text"
              color="error"
              disabled={Object.keys(table.state.selectedRowIds).length === 0}
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              startIcon={<Icon name="plus" />}
              onClick={addRow}
              data-testid={dtid.productsAftersalesRulesAddNew}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            data-testid={dtid.productsAftersalesRulesTable}
            table={table}
            emptyLabel={<TransField i18nKey="noAftersalesRules" />}
          />
        </FormProvider>
      </CardContent>
      <ConfirmDeleteModal
        handleDelete={removeSelectedRows}
        title={<TransModal i18nKey="deleteAftersalesRules" />}
        description={
          <TransModal
            i18nKey="aftersalesRulesDescription"
            values={{ count: table.selectedFlatRows.length }}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  );
};
