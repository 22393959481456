import {
  Button,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  TableDragDropProps,
  tableRowClickEventWrapper,
  useDraggable,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AncillaryType } from 'dto/ancillaryTypes';
import {
  deleteAncillaryTypes,
  getAncillaryTypes,
  reorderAncillaryTypes,
} from 'features/ancillaryTypes/ancillaryTypesActions';
import { ancillaryTypesSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { ancillaryTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import dtid from 'helpers/dtid';

interface AncillaryTypesTableProps {}

export const AncillaryTypesTable: FC<AncillaryTypesTableProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const alert = useAlert();

  const { open: isOpen, onOpen, onClose } = useModal();
  const ancillaryTypes = useSelector(ancillaryTypesSelector);
  const loading = useSelector(ancillaryTypesLoadingSelector);
  const data = useMemo(() => ancillaryTypes, [ancillaryTypes]);

  const link = useCallback(
    (row: Row<AncillaryType>) => `/ancillary-types/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<AncillaryType>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        id: 'owner.name',
        accessor: ({ owner }) => owner.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="order" />,
      },
      {
        id: 'localizations',
        accessor: ({ localizations }) => localizations.length || '-',
        Header: <TransTableHead i18nKey="localizations" />,
      },
    ],
    [link]
  );

  const initialState = useMemo(() => ({ pageSize: 10 }), []);
  const table = useTable<AncillaryType>(
    {
      data,
      columns,
      initialState,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useDraggable
  );

  const { selectedFlatRows } = table;

  useTableRowHighlight(id, table);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteAncillaryTypes(selectedFlatRows.map(({ original }) => original.id))
    ).unwrap();

    onClose();
    alert.success(
      <TransAlert
        i18nKey="ancillaryTypeDeleted"
        values={{ count: table.selectedFlatRows.length }}
      />
    );

    dispatch(getAncillaryTypes());
  }, [
    dispatch,
    selectedFlatRows,
    onClose,
    alert,
    table.selectedFlatRows.length,
  ]);

  const handleDragEnd = useCallback<
    TableDragDropProps<AncillaryType>['onDragEnd']
  >(
    ({ data }) => {
      dispatch(reorderAncillaryTypes(data));
    },
    [dispatch]
  );

  return (
    <SearchResult
      results={!!data.length}
      loading={loading}
      i18nKey="noDataAdded"
    >
      <Table
        data-testid={dtid.ancillaryTypesResults}
        caption={
          <TableCaption>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <span />
              <Stack direction="row" alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  <TransSubtitle
                    i18nKey="selectedQty"
                    values={{
                      num: table.selectedFlatRows.length,
                    }}
                  />
                </Typography>
                <Button
                  variant="text"
                  startIcon={<Icon name="delete" />}
                  onClick={onOpen}
                  disabled={table.selectedFlatRows.length === 0}
                  color="error"
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
              </Stack>
            </Stack>
          </TableCaption>
        }
        table={table}
        getRowProps={(_, { row }) => ({
          sx: {},
          onClick: () => history.push(link(row)),
        })}
        draggable
        onDragEnd={handleDragEnd}
      />
      <ConfirmDeleteModal
        handleDelete={handleRowsRemove}
        title={<TransModal i18nKey="deleteAncillaryTypes" />}
        description={
          <TransModal
            i18nKey="ancillaryTypesDeletionDescription"
            values={{ count: table.selectedFlatRows.length }}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </SearchResult>
  );
};
