import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  addNewDate: <Trans i18nKey="button.addNewDate" defaults="Add new date" />,
  addNewDatePeriod: (
    <Trans i18nKey="button.addNewDatePeriod" defaults="Add new date period" />
  ),
  addNewTimePeriod: (
    <Trans i18nKey="button.addNewTimePeriod" defaults="Add new time period" />
  ),
  addProducts: <Trans i18nKey="button.addProducts" defaults="Add products" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  copy: <Trans i18nKey="button.copy" defaults="Copy" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  removeConditions: (
    <Trans i18nKey="button.removeConditions" defaults="Remove conditions" />
  ),
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  selectAll: <Trans i18nKey="button.selectAll" defaults="Select all" />,
  selectStop: <Trans i18nKey="table.head.selectStop" defaults="Select stop" />,
  submit: <Trans i18nKey="table.head.submit" defaults="Submit" />,
});
