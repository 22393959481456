import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'store/utils';
import { useAlert } from 'react-alert';
import { SalesRulesPayload } from 'dto/tariff';
import { TransAlert } from 'i18n/trans/alert';
import {
  Button,
  FormControl,
  FormProvider,
  SelectField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'react-redux';
import {
  selectCurrentTariff,
  tariffSalesRulesSelector,
} from 'features/tariff/tariffSelectors';
import {
  getTariffSalesRules,
  updateTariffSalesRules,
} from 'features/tariff/tariffActions';
import { fetchRetailerContracts } from 'features/tariff/tariffService';
import { Option } from '@fleet/shared/dto/option';

export const TariffSalesChannelsAndRetailers: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentTariff = useSelector(selectCurrentTariff);
  const currentSalesRules = useSelector(tariffSalesRulesSelector);
  const salesChannelOptions = useClassificationOptions(
    ClassificationGroup.SALES_CHANNEL
  );
  const [retailerOptions, setRetailerOptions] = useState<Array<Option<number>>>(
    []
  );

  const fetchRetailerContractOptions = useCallback(
    async () =>
      setRetailerOptions(
        (await fetchRetailerContracts(currentTariff!.owner.id)).reduce(
          (res, { retailer }) => {
            const retailerAlreadyStored = res.find(
              ({ value }) => value === retailer.id
            );

            if (!retailerAlreadyStored) {
              res.push({
                label: retailer.name,
                value: retailer.id,
              });
            }

            return res;
          },
          [] as Array<Option<number>>
        )
      ),
    [currentTariff]
  );

  useEffect(() => {
    if (currentTariff?.owner) {
      fetchRetailerContractOptions();
    }
  }, [currentTariff, fetchRetailerContractOptions]);

  const onSubmit = useCallback(
    async ({ salesChannelIds, retailerIds }: SalesRulesPayload) =>
      formSubmit(async () => {
        await dispatch(
          updateTariffSalesRules({
            salesChannels: salesChannelIds.map((id) => ({
              id,
            })),
            retailers: retailerIds.map((id) => ({ id })),
          })
        ).unwrap();
        await dispatch(getTariffSalesRules(currentTariff!.id));
        alert.success(
          <TransAlert i18nKey="salesChannelsAndRetailersUpdated" />
        );
      }),
    [dispatch, currentTariff, alert]
  );

  const initialValues = useMemo(
    () => ({
      ...(currentSalesRules && {
        salesChannelIds: currentSalesRules.salesChannels?.map(({ id }) => id),
        retailerIds: currentSalesRules.retailers?.map(({ id }) => id),
      }),
    }),
    [currentSalesRules]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<SalesRulesPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={4} spacing={2}>
          <Grid item xs={1}>
            <SelectField
              label={<TransField i18nKey="salesChannels" />}
              name="salesChannelIds"
              multiple
              options={salesChannelOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              label={<TransField i18nKey="retailers" />}
              name="retailerIds"
              multiple
              options={retailerOptions}
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" flexWrap="nowrap">
              <FormControl label="&nbsp;">
                <Button
                  variant="text"
                  color="primary"
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={!dirty}
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetChanges" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  type="submit"
                  variant="contained"
                  icon="check"
                  disabled={submitting}
                >
                  <TransButton i18nKey="save" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
