import {
  Button,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  getEditablePropsForDictionaries,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Stack } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { ProductLocalization } from 'dto/product';
import {
  deleteProductLocalizations,
  getProduct,
  updateOrCreateProductLocalization,
} from 'features/product/productActions';
import { currentProductSelector } from 'features/product/productSelector';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import dtid from 'helpers/dtid';

interface LocalizationsProps {}

export const Localizations: FC<LocalizationsProps> = () => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const product = useSelector(currentProductSelector);
  const dispatch = useDispatch();
  const localizations = useMemo(
    () => product?.localizations ?? [],
    [product?.localizations]
  );

  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);

  const usedTranslations = useMemo(
    () => localizations.map(({ language: { id } }) => id),
    [localizations]
  );
  const availableCultureOptions = useMemo(
    () =>
      cultureOptions.filter(({ value }) => !usedTranslations.includes(value)),
    [cultureOptions, usedTranslations]
  );

  const columns: TableColumns<ProductLocalization> = useMemo(
    () => [
      {
        id: 'language.id',
        accessor: ({ language }) => language?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: ({ value }) =>
          getEditablePropsForDictionaries({
            value,
            availableOptions: availableCultureOptions,
            dictionary: cultures,
          }),
        width: 100,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
        width: '50%',
      },
    ],
    [availableCultureOptions, cultures]
  );

  const { form } = useForm<{ rows: Array<ProductLocalization> }>({
    initialValues: {
      rows: localizations,
    },
  });

  const handleRowUpdate = useCallback(
    async ({
      language: { id: languageId },
      description,
    }: ProductLocalization) => {
      if (!product) {
        return;
      }

      dispatch(
        updateOrCreateProductLocalization({
          productId: product.id,
          languageId,
          description,
        })
      );
    },
    [dispatch, product]
  );

  const handleRowsRemoved = useCallback(
    async (rows: Array<ProductLocalization>) => {
      if (!product) {
        return;
      }

      await dispatch(
        deleteProductLocalizations({
          id: product.id,
          languageIds: rows.map(({ language: { id } }) => id),
        })
      ).unwrap();

      onClose();

      dispatch(getProduct(product.id));
    },
    [dispatch, onClose, product]
  );

  const table = useFormTable<ProductLocalization>(
    {
      data: localizations,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsRemoved,
  });

  return (
    <FormProvider form={form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          variant="text"
          color="error"
          onClick={onOpen}
          startIcon={<Icon name="trash" size={20} />}
          disabled={Object.keys(table.state.selectedRowIds).length === 0}
          data-testid={dtid.productsLocalizationsDeleteSelected}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={addRow}
          data-testid={dtid.productsLocalizationsAddNew}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        data-testid={dtid.productsLocalizationsTable}
        getHeaderGroupProps={{
          sx: { backgroundColor: 'common.white' },
        }}
        table={table}
        emptyLabel={<TransField i18nKey="noLocalizations" />}
      />
      <ConfirmDeleteModal
        data-testid={dtid.productsLocalizationsDeleteModal}
        handleDelete={removeSelectedRows}
        title={<TransModal i18nKey="deleteLocalizations" />}
        description={
          <TransModal
            i18nKey="localizationsDeletionDescription"
            values={{ count: table.selectedFlatRows.length }}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </FormProvider>
  );
};
