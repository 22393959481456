import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from 'components/Header';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { ProductCategory } from 'routes/productCategory/ProductCategory';
import { Products } from 'routes/products/Products';
import { Tariff } from 'routes/tariff/Tariff';
import {
  AppVersion,
  AuthWrapper,
  EnvironmentBanner,
  useAuth,
} from '@fleet/shared';
import { AncillaryTypes } from 'routes/ancillaryTypes/AncillaryTypes';
import { getAncillaryTypes } from 'features/ancillaryTypes/ancillaryTypesActions';

export const Application = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const appLoading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
      dispatch(getAncillaryTypes());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={appLoading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          <Switch>
            <Route
              exact
              path="/product-category/:action(create|edit)?/:id?"
              component={ProductCategory}
            />
            <Route
              exact
              path="/products/:action(create|edit)?/:id?"
              component={Products}
            />
            <Route
              exact
              path="/tariff/:action(create|edit)?/:id?"
              component={Tariff}
            />
            <Route
              exact
              path="/ancillary-types/:action(create|edit)?/:id?"
              component={AncillaryTypes}
            />
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
