import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentTariffSelector,
  tariffSupplementsSelector,
} from 'features/tariff/tariffSelectors';
import {
  api,
  FormControl,
  FormField,
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTableControls,
  makeClassificationOptions,
  ConfirmDeleteModal,
  useModal,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { TariffSupplement } from 'dto/tariff';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Option } from '@fleet/shared/dto/option';
import {
  getTariffSupplementProducts,
  getTariffSupplements,
} from 'features/tariff/tariffActions';
import { StopSelectionModal } from 'routes/tariff/tariffFormAccordion/supplements/StopSelectionModal';
import { metaError } from '@fleet/shared/form/FormField';
import _toInteger from 'lodash/toInteger';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';

interface SelectedFieldStop {
  rowId: string;
  stopKey: string;
}

export const TariffSupplements: FC = () => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const data = useSelector(tariffSupplementsSelector);
  const currentTariff = useSelector(currentTariffSelector)!;
  const dispatch = useDispatch();
  const [stopSelectionData, setStopSelectionData] = useState<
    SelectedFieldStop | undefined
  >();
  const [productOptions, setProductOptions] = useState<Array<Option<string>>>(
    []
  );
  const fareTypeOptions = useClassificationOptions(
    ClassificationGroup.FARE_TYPE
  );
  const fareCategoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );
  const supplementAreaRuleOptions = useClassificationOptions(
    ClassificationGroup.SUPPLEMENT_AREA_RULE
  );

  const fetchProductOptions = useCallback(
    async (tariffOwnerId: number) => {
      setProductOptions(
        makeClassificationOptions(
          (
            await dispatch(getTariffSupplementProducts(tariffOwnerId)).unwrap()
          ).map(({ id, description }) => ({ id, name: description }))
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentTariff) {
      fetchProductOptions(currentTariff.owner.id);
    }
  }, [currentTariff, fetchProductOptions]);

  const { form, values } = useForm<{ rows: Array<TariffSupplement> }>({
    initialValues: {
      rows: data,
    },
    subscription: { values: true },
  });

  const changeStopFieldValue = useCallback(
    (
      selectedField: SelectedFieldStop,
      selectedStop: Classifier | undefined
    ) => {
      const { rowId, stopKey } = selectedField;
      const newValues = values.rows.map((row, index) => {
        if (index !== _toInteger(rowId)) {
          return row;
        }

        return { ...row, [stopKey]: selectedStop };
      });

      form.change('rows', newValues);
    },
    [form, values.rows]
  );

  const renderSearchStop = useCallback(
    ({ cell: { row, column } }) => (
      <FormField<{ id?: 'startStop' | 'endStop'; name?: string }>
        name={`rows[${row.id}].${column.id}`}
        required
        render={({ input, meta }) =>
          input.value.name ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{input.value.name}</Typography>
              {row.state.editable && (
                <IconButton
                  onClick={() =>
                    changeStopFieldValue(
                      {
                        rowId: row.id,
                        stopKey: column.id,
                      },
                      undefined
                    )
                  }
                >
                  <Icon name="close" />
                </IconButton>
              )}
            </Stack>
          ) : (
            <FormControl error={metaError(meta)}>
              <Button
                style={{ paddingLeft: '0', justifyContent: 'flex-start' }}
                startIcon={<Icon name="search" />}
                onClick={() =>
                  setStopSelectionData({
                    rowId: row.id,
                    stopKey: column.id,
                  })
                }
              >
                <TransButton i18nKey="selectStop" />
              </Button>
            </FormControl>
          )
        }
      />
    ),
    [changeStopFieldValue]
  );

  const columns: TableColumns<TariffSupplement> = useMemo(
    () => [
      {
        id: 'product.id',
        accessor: ({ product }) => product?.id,
        Header: <TransTableHead i18nKey="product" />,
        type: 'select',
        editableProps: {
          options: productOptions,
        },
      },
      {
        id: 'primaryFareType.id',
        accessor: ({ primaryFareType }) => primaryFareType?.id,
        Header: <TransTableHead i18nKey="primaryFareType" />,
        type: 'select',
        editableProps: {
          options: fareTypeOptions,
        },
      },
      {
        id: 'fareCategory.id',
        accessor: ({ fareCategory }) => fareCategory?.id,
        Header: <TransTableHead i18nKey="fareCategory" />,
        type: 'select',
        editableProps: {
          options: fareCategoryOptions,
        },
      },
      {
        id: 'startStop',
        Cell: renderSearchStop,
        Header: <TransTableHead i18nKey="zoneStart" />,
      },
      {
        id: 'endStop',
        Cell: renderSearchStop,
        Header: <TransTableHead i18nKey="zoneEnd" />,
      },
      {
        id: 'supplementAreaRule.id',
        accessor: ({ supplementAreaRule }) => supplementAreaRule?.id,
        Header: <TransTableHead i18nKey="zoneRule" />,
        type: 'select',
        editableProps: {
          options: supplementAreaRuleOptions,
        },
      },
    ],
    [
      fareCategoryOptions,
      fareTypeOptions,
      productOptions,
      renderSearchStop,
      supplementAreaRuleOptions,
    ]
  );

  const handleRowUpdate = useCallback(
    async (values: TariffSupplement) => {
      const {
        id,
        product,
        primaryFareType,
        fareCategory,
        startStop,
        endStop,
        supplementAreaRule,
      } = values;

      await (id ? api.put : api.post)(
        `/tariffs/${currentTariff.id}/supplements${id ? `/${id}` : ''}`,
        {
          productId: product.id,
          primaryFareTypeId: primaryFareType.id,
          fareCategoryId: fareCategory.id,
          startStopId: startStop.id,
          endStopId: endStop.id,
          supplementAreaRuleId: supplementAreaRule.id,
        }
      );

      dispatch(getTariffSupplements(currentTariff.id));
    },
    [currentTariff.id, dispatch]
  );

  const table = useFormTable<TariffSupplement>(
    {
      data,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const handleRowsDeleted = useCallback(
    async (rows: Array<TariffSupplement>) => {
      await Promise.all(
        rows.map((row) =>
          api.delete(`/tariffs/${currentTariff.id}/supplements/${row.id}`)
        )
      );

      onClose();

      dispatch(getTariffSupplements(currentTariff.id));
    },
    [currentTariff.id, dispatch, onClose]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  return (
    <>
      <StopSelectionModal
        stopData={stopSelectionData}
        onSubmit={(selectedStop) => {
          stopSelectionData &&
            changeStopFieldValue(stopSelectionData, selectedStop);

          setStopSelectionData(undefined);
        }}
        onClose={() => setStopSelectionData(undefined)}
      />
      <FormProvider form={form}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={{ mb: 1, mt: 1 }}
        >
          <Button
            color="error"
            startIcon={<Icon name="delete" />}
            onClick={onOpen}
            disabled={!Object.keys(table.state.selectedRowIds).length}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button startIcon={<Icon name="plus" />} onClick={addRow}>
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          table={table}
          getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
          emptyLabel={<TransField i18nKey="noSupplements" />}
        />
        <ConfirmDeleteModal
          handleDelete={removeSelectedRows}
          title={<TransModal i18nKey="deleteSupplements" />}
          description={
            <TransModal
              i18nKey="supplementsDeletionDescription"
              values={{ count: table.selectedFlatRows.length }}
            />
          }
          isOpen={isOpen}
          onClose={onClose}
        />
      </FormProvider>
    </>
  );
};
