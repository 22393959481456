import {
  Button,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  getEditablePropsForDictionaries,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { Stack } from '@mui/material';
import {
  AncillaryTypeLocalization,
  AncillaryTypeSubtype,
} from 'dto/ancillaryTypes';
import { ClassificationGroup } from 'dto/classification';
import {
  deleteAncillaryTypeLocalizations,
  deleteAncillaryTypeSubtypeLocalizations,
  getAncillaryType,
  getAncillaryTypeSubtypes,
  getAncillaryTypes,
  updateOrCreateAncillaryTypeLocalizations,
  updateOrCreateAncillaryTypeSubtypeLocalizations,
} from 'features/ancillaryTypes/ancillaryTypesActions';
import { ancillaryTypeSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import dtid from 'helpers/dtid';

interface AncillaryTypesLocalizationsTableProps {
  localizations: Array<AncillaryTypeLocalization>;
  onRowUpdate: (payload: AncillaryTypeLocalization) => Promise<void>;
  onRowsRemoved: (rows: Array<AncillaryTypeLocalization>) => Promise<void>;
}

interface AncillaryTypesLocalizationsProps {
  data?: AncillaryTypeSubtype;
}

const AncillaryTypesLocalizationsTable: FC<AncillaryTypesLocalizationsTableProps> =
  ({ localizations, onRowUpdate, onRowsRemoved }) => {
    const { open: isOpen, onOpen, onClose } = useModal();

    const cultures = useClassificationMap(ClassificationGroup.CULTURE);
    const cultureOptions = useClassificationOptions(
      ClassificationGroup.CULTURE
    );

    const usedTranslations = useMemo(
      () => localizations.map(({ languageId }) => languageId),
      [localizations]
    );
    const availableCultureOptions = useMemo(
      () =>
        cultureOptions.filter(({ value }) => !usedTranslations.includes(value)),
      [cultureOptions, usedTranslations]
    );

    const columns: TableColumns<AncillaryTypeLocalization> = useMemo(
      () => [
        {
          accessor: 'languageId',
          Header: <TransTableHead i18nKey="language" />,
          type: 'select',
          editableProps: ({ value }) =>
            getEditablePropsForDictionaries({
              value,
              availableOptions: availableCultureOptions,
              dictionary: cultures,
            }),
          width: 100,
        },
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
        },
        {
          accessor: 'description',
          Header: <TransTableHead i18nKey="description" />,
          width: '50%',
        },
      ],
      [availableCultureOptions, cultures]
    );

    const { form } = useForm<{ rows: Array<AncillaryTypeLocalization> }>({
      initialValues: {
        rows: localizations,
      },
    });

    const handleRowsRemoved = useCallback(
      async (rows: Array<AncillaryTypeLocalization>) => {
        await onRowsRemoved(rows);
        onClose();
      },
      [onClose, onRowsRemoved]
    );

    const table = useFormTable<AncillaryTypeLocalization>(
      {
        data: localizations,
        columns,
        form,
        onRowUpdate,
      },
      useRowSelect,
      useIndeterminateRowSelectCheckbox,
      useRowEditActions
    );

    const { addRow, removeSelectedRows } = useFormTableControls({
      table,
      form,
      removeQuery: handleRowsRemoved,
    });

    return (
      <FormProvider form={form}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            startIcon={<Icon name="delete" />}
            onClick={onOpen}
            disabled={table.selectedFlatRows.length === 0}
            color="error"
            data-testid={dtid.ancillaryLocalizationsDeleteSelected}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
            data-testid={dtid.ancillaryLocalizationsAddNew}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          data-testid={dtid.ancillaryLocalizationsTable}
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getHeaderProps={{ style: { backgroundColor: 'white' } }}
          table={table}
          emptyLabel={<TransField i18nKey="noLocalizations" />}
        />
        <ConfirmDeleteModal
          handleDelete={removeSelectedRows}
          title={<TransModal i18nKey="deleteLocalizations" />}
          description={
            <TransModal
              i18nKey="localizationsDeletionDescription"
              values={{ count: table.selectedFlatRows.length }}
            />
          }
          isOpen={isOpen}
          onClose={onClose}
        />
      </FormProvider>
    );
  };

export const AncillaryTypesLocalizations: FC<AncillaryTypesLocalizationsProps> =
  ({ data }) => {
    const dispatch = useDispatch();
    const ancillaryType = useSelector(ancillaryTypeSelector);
    const { id, localizations = [] } = data ?? ancillaryType!;

    const handleRowUpdate = useCallback(
      async (payload: AncillaryTypeLocalization) => {
        if (data) {
          await dispatch(
            updateOrCreateAncillaryTypeSubtypeLocalizations({
              id,
              typeId: data.ancillaryTypeId,
              payload,
            })
          );
        } else {
          await dispatch(
            updateOrCreateAncillaryTypeLocalizations({ id, payload })
          );
        }
      },
      [data, dispatch, id]
    );

    const handleRowsRemoved = useCallback(
      async (rows: Array<AncillaryTypeLocalization>) => {
        const languageIds = rows
          .map(({ languageId }) => languageId)
          .filter(Boolean);

        if (data) {
          await dispatch(
            deleteAncillaryTypeSubtypeLocalizations({
              id,
              languageIds,
            })
          ).unwrap();

          dispatch(getAncillaryTypeSubtypes(data.ancillaryTypeId));
        } else {
          await dispatch(
            deleteAncillaryTypeLocalizations({
              id,
              languageIds,
            })
          ).unwrap();

          dispatch(getAncillaryType(id));
          dispatch(getAncillaryTypes());
        }
      },
      [data, dispatch, id]
    );

    if (!id) {
      return null;
    }

    return (
      <AncillaryTypesLocalizationsTable
        localizations={localizations}
        onRowUpdate={handleRowUpdate}
        onRowsRemoved={handleRowsRemoved}
      />
    );
  };
