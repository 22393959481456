import {
  Button,
  FormProvider,
  Icon,
  formSubmit,
  useForm,
  DatesArrayField,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import {
  TimeDependentConditions,
  TimeDependentConditionsValues,
} from 'dto/tariff';
import { TransButton } from 'i18n/trans/button';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { TravelDatePeriods } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TravelDatePeriods';
import { TravelTimePeriods } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TravelTimePeriods';
import { useDispatch } from 'store/utils';
import {
  createOrUpdateTariffTimeDependentConditions,
  getTariffTimeDependentConditions,
} from 'features/tariff/tariffActions';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import _mapValues from 'lodash/mapValues';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { TravelDaysOfTheWeek } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TravelDaysOfTheWeek';
import { OpenUntilMinutesBeforeDeparture } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/OpenUntilMinutesBeforeDeparture';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  convertNumberToTimeDuration,
  convertTimeDurationToNumber,
} from '@fleet/shared/utils/timeDuration';

interface TimeDependentConditionProps {
  data: TimeDependentConditions;
  tariffId?: string;
}

export const TimeDependentCondition: FC<TimeDependentConditionProps> = ({
  data,
  tariffId,
}) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    ({
      travelDatePeriods,
      travelTimePeriods,
      travelBlackoutDates,
      timeDurationOpenUntilBeforeDeparture,
      ...rest
    }: TimeDependentConditionsValues) =>
      formSubmit(async () => {
        const [isoDateFormat, isoTimeFormat] = isoDateTimeFormat.split("'T'");

        const timePeriods = travelTimePeriods.map((period) =>
          _mapValues(period, (date) => formatDate(date, isoTimeFormat))
        );

        const blackOutDates = travelBlackoutDates.map((date) =>
          formatDate(date, isoDateFormat)
        );

        const datePeriods = travelDatePeriods.map((period) =>
          _mapValues(period, (date) => formatDate(date, isoDateFormat))
        );

        await dispatch(
          createOrUpdateTariffTimeDependentConditions({
            ...rest,
            travelTimePeriods: timePeriods,
            travelBlackoutDates: blackOutDates,
            travelDatePeriods: datePeriods,
            openUntilMinutesBeforeDeparture: convertTimeDurationToNumber(
              timeDurationOpenUntilBeforeDeparture
            ),
          })
        ).unwrap();

        await dispatch(getTariffTimeDependentConditions(tariffId!)).unwrap();
        alert.success(<TransAlert i18nKey="timeDependantConditionsUpdated" />);
      }),
    [dispatch, tariffId, alert]
  );

  const initialValues = useMemo<TimeDependentConditionsValues>(
    () => ({
      ...data,
      travelPeriodTypeId: data.travelPeriodType.id,
      travelTimePeriods: data.travelTimePeriods.map(({ from, to }) => ({
        from: `2001-01-01T${from}`,
        to: `2001-01-01T${to}`,
      })),
      timeDurationOpenUntilBeforeDeparture: convertNumberToTimeDuration(
        data.openUntilMinutesBeforeDeparture
      ),
    }),
    [data]
  );

  const { form, handleSubmit, submitting } =
    useForm<TimeDependentConditionsValues>({
      initialValues,
      onSubmit,
      subscription: { submitting: true, values: true },
    });

  const handleReset = useCallback(() => form.reset(), [form]);

  const renderBlock = ({
    title,
    content,
    columns = 4,
  }: {
    title: ReactNode;
    content: ReactNode;
    columns?: number;
  }) => (
    <>
      <Stack sx={{ p: 2, background: '#F6F6F6' }}>
        <Typography variant="subtitle">{title}</Typography>
      </Stack>
      <Grid container columns={columns} spacing={2} sx={{ p: 2 }}>
        {content}
      </Grid>
    </>
  );

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Stack sx={{ p: 2 }}>
          {renderBlock({
            title: <TransSubtitle i18nKey="travelDatePeriods" />,
            content: <TravelDatePeriods />,
          })}
          {renderBlock({
            title: <TransSubtitle i18nKey="travelTimePeriods" />,
            content: <TravelTimePeriods />,
          })}
          {renderBlock({
            title: <TransSubtitle i18nKey="travelBlackoutDates" />,
            content: (
              <>
                <Grid item xs={12}>
                  <DatesArrayField name="travelBlackoutDates" disablePast />
                </Grid>
              </>
            ),
          })}
          {renderBlock({
            title: <TransSubtitle i18nKey="travelDaysOfTheWeek" />,
            content: <TravelDaysOfTheWeek />,
            columns: 3,
          })}
          {renderBlock({
            title: <TransSubtitle i18nKey="openUntilPriorToDeparture" />,
            content: <OpenUntilMinutesBeforeDeparture />,
          })}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Button variant="text" onClick={handleReset}>
            <TransButton i18nKey="resetChanges" />
          </Button>
          <Button
            disabled={submitting}
            startIcon={<Icon name="check" />}
            variant="contained"
            type="submit"
          >
            <TransButton i18nKey="save" />
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
