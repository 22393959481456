import {
  CardHeader,
  ConditionField,
  DrawerForm,
  DrawerFormProps,
  FormProvider,
  formSubmit,
  Icon,
  makeClassificationOptions,
  RadioGroupField,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import {
  Button,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TariffPayload } from 'dto/tariff';
import { tariffLoadingSelector } from 'features/loading/loadingSelectors';
import {
  copyTariffById,
  createTariff,
  getTariffById,
  getTariffs,
  setTariff,
  updateTariff,
} from 'features/tariff/tariffActions';
import { currentTariffSelector } from 'features/tariff/tariffSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { Option } from '@fleet/shared/dto/option';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { TariffFormAccordion } from 'routes/tariff/tariffFormAccordion/TariffFormAccordion';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { Tooltip } from '@fleet/shared/mui';
import {
  fetchCarriers,
  fetchProducts,
  fetchPromotionalDiscounts,
} from 'features/tariff/tariffService';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { PassengerType } from 'routes/tariff/PassengerType';
import _omit from 'lodash/omit';
import { Product, ProductObjectType } from 'dto/product';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { isProductAncillary } from 'routes/products/productForms/AncillaryForm';

const useStyles = makeStyles(
  () => ({
    customTextField: {
      height: '68px',
    },
  }),
  {
    name: 'TariffForm',
  }
);

export const TariffForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const currentTariff = useSelector(currentTariffSelector);
  const loading = useSelector(tariffLoadingSelector);
  const history = useHistory();
  const alert = useAlert();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const [productOptions, setProductOptions] = useState<
    Array<Option<string> & Omit<Product, 'id' | 'description'>>
  >([]);
  const [carrierOptions, setCarrierOptions] = useState<Array<Option<string>>>(
    []
  );
  const [promotionalDiscountOptions, setPromotionalDiscountOptions] = useState<
    Array<Option<number | string>>
  >([]);
  const fareTypeOptions = useClassificationOptions(
    ClassificationGroup.FARE_TYPE
  );
  const fareCategoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );
  const pricingDistanceTypeOptions = useClassificationOptions(
    ClassificationGroup.PRICING_DISTANCE_TYPE
  );
  const distanceFareImplementationMethodOptions = useClassificationOptions(
    ClassificationGroup.DISTANCE_FARE_IMPLEMENTATION_METHOD
  );
  const reducedMobilityTypeOptions = useClassificationOptions(
    ClassificationGroup.REDUCED_MOBILITY_TYPE
  );

  useEffect(() => {
    dispatch(setTariff());
    if (isEditing && id) {
      dispatch(getTariffById(id));
    }
    return () => {
      dispatch(setTariff());
    };
  }, [dispatch, id, isEditing]);

  const fetchProductOptions = useCallback(
    async (ownerId: number) =>
      setProductOptions(
        (await fetchProducts(ownerId)).map(({ id, name, ...rest }) => ({
          ...rest,
          value: id,
          label: name,
        }))
      ),
    []
  );

  const fetchCarrierOptions = useCallback(
    async (ownerId: number) =>
      setCarrierOptions(
        makeClassificationOptions(await fetchCarriers(ownerId))
      ),
    []
  );

  const fetchPromotionalDiscountOptions = useCallback(
    async (ownerId: number) =>
      setPromotionalDiscountOptions(
        makeClassificationOptions(await fetchPromotionalDiscounts(ownerId))
      ),
    []
  );

  const handleGoBack = useCallback(() => {
    history.replace('/tariff');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (event, reason) => {
      if (reason === 'close') {
        setTariff();
        handleGoBack();
      }
    },
    [handleGoBack]
  );

  const advancedTrimmer = (text?: string) => {
    if (!text) {
      return [];
    }

    return text.replaceAll(/\s/g, '').split(',');
  };

  const onSubmit = useCallback(
    (values: TariffPayload) =>
      formSubmit(async () => {
        const payload = {
          ...values,
          serviceCodes: advancedTrimmer(values.serviceCodes),
          lineNumbers: advancedTrimmer(values.lineNumbers),
          distanceFareImplementationMethodId:
            values.primaryFareTypeId === 'FARE_TYPE.DISTANCE'
              ? values.distanceFareImplementationMethodId
              : null,
          pricingDistanceTypeId:
            values.primaryFareTypeId === 'FARE_TYPE.DISTANCE'
              ? values.pricingDistanceTypeId
              : null,
        };

        const actionTariff = !id ? createTariff : updateTariff;
        const data = await dispatch(actionTariff(payload)).unwrap();

        if (id) {
          alert.success(<TransAlert i18nKey="tariffUpdated" />);
        } else {
          alert.success(<TransAlert i18nKey="tariffCreated" />);
          history.replace(`edit/${data.id}`);
        }

        dispatch(setTariff(data));
        dispatch(getTariffs());
      }),
    [dispatch, history, id, alert]
  );

  const initialValues = useMemo<Partial<TariffPayload>>(() => {
    if (!currentTariff) {
      return {
        ownerId: currentBusinessEntityId,
        arePromotionalDiscountsAllowed: true,
        isThruFareAllowed: true,
        isDiscountVoucherAllowed: true,
      };
    }

    const {
      owner,
      product,
      primaryFareType,
      fareCategory,
      passengerTypes,
      carriers,
      serviceCodes,
      lineNumbers,
      promotionalDiscount,
      passengerTypeDiscount,
      distanceFareImplementationMethod,
      pricingDistanceType,
      reducedMobilityTypes,
      ...rest
    } = _omit(currentTariff, 'roundTripConditions');

    return {
      ...rest,
      ownerId: owner.id,
      productId: product.id,
      categoryClassificationId: product.categoryClassification.id,
      subCategoryClassificationId: product.subCategoryClassification?.id,
      primaryFareTypeId: primaryFareType.id,
      fareCategoryId: fareCategory.id,
      passengerTypes: passengerTypes.map(({ type, discount }) => ({
        id: type.id,
        discountId: discount?.id,
      })),
      carrierIds: carriers.map(({ id }) => id),
      serviceCodes: serviceCodes.join(', '),
      lineNumbers: lineNumbers.join(', '),
      promotionalDiscountId: promotionalDiscount?.id,
      passengerTypeDiscountId: passengerTypeDiscount?.id,
      distanceFareImplementationMethodId: distanceFareImplementationMethod?.id,
      pricingDistanceTypeId: pricingDistanceType?.id,
      reducedMobilityTypeIds: reducedMobilityTypes?.map(({ id }) => id),
    };
  }, [currentBusinessEntityId, currentTariff]);

  const { form, handleSubmit, dirty, submitting, values } =
    useForm<TariffPayload>({
      initialValues,
      onSubmit,
      subscription: { dirty: true, submitting: true, values: true },
    });

  const isAncillaryProductWithBookingSalesLevel = useMemo(() => {
    const selectedProduct = productOptions.find(
      ({ value }) => value === values.productId
    );

    if (!selectedProduct) {
      return false;
    }

    const isAncillaryProduct =
      selectedProduct.categoryClassificationId === 'PRODUCT_CATEGORY.ANCILLARY';
    let isBookingSalesLevel = false;

    if (isProductAncillary(selectedProduct)) {
      isBookingSalesLevel =
        selectedProduct.salesLevel?.id === 'PRODUCT_SALES_LEVEL.BOOKING';
    }

    return isAncillaryProduct && isBookingSalesLevel;
  }, [productOptions, values.productId]);

  const handleCopy = useCallback(async () => {
    if (!id) {
      return;
    }

    const newId = await dispatch(copyTariffById(id)).unwrap();
    alert.success(<TransAlert i18nKey="tariffCopied" />);

    await dispatch(getTariffs());
    history.push(`/tariff/edit/${newId}`);
  }, [alert, dispatch, history, id]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  useEffect(() => {
    if (values.ownerId) {
      fetchProductOptions(values.ownerId);
      fetchCarrierOptions(values.ownerId);
      fetchPromotionalDiscountOptions(values.ownerId);
    }
  }, [
    values.ownerId,
    fetchCarrierOptions,
    fetchProductOptions,
    fetchPromotionalDiscountOptions,
  ]);

  const renderGridDivider = () => (
    <Grid item xs={4}>
      <Divider />
    </Grid>
  );

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {!isEditing ? (
                  <TransSubtitle i18nKey="newTariff" />
                ) : loading ? (
                  <>&nbsp;</>
                ) : (
                  currentTariff?.description
                )}
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={handleGoBack}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent>
            <Grid container columns={4} spacing={2}>
              <Grid item xs={1}>
                <SelectOwnerField
                  businessEntities={businessEntities}
                  allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="code"
                  label={<TransField i18nKey="code" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="description"
                  label={<TransField i18nKey="description" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="shortDescription"
                  label={<TransField i18nKey="shortDescription" />}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="productId"
                  label={<TransField i18nKey="product" />}
                  options={productOptions}
                  onChange={(id) => {
                    const productOption = productOptions.find(
                      (p) => p.value === id
                    );

                    form.batch(() => {
                      form.change(
                        'categoryClassificationId',
                        productOption?.categoryClassificationId
                      );
                      form.change(
                        'subCategoryClassificationId',
                        productOption?.subCategoryClassificationId
                      );
                      form.change('primaryFareTypeId');
                      form.resetFieldState('passengerTypes');
                    });
                  }}
                  disabled={!!currentTariff?.id}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <ConditionField
                  when="categoryClassificationId"
                  is={[
                    ProductObjectType.ADMISSION,
                    ProductObjectType.RESERVATION,
                  ]}
                >
                  {(isProductAdmissionOrReservation) => (
                    <PassengerType
                      ownerId={values.ownerId}
                      required={isProductAdmissionOrReservation}
                    />
                  )}
                </ConditionField>
              </Grid>
              <ConditionField
                when="subCategoryClassificationId"
                is="PRODUCT_SUB_CATEGORY.SEAT_SELECTION_FEE"
              >
                <Grid item xs={1}>
                  <TextField
                    name="inventoryRank"
                    label={<TransField i18nKey="inventoryRank" />}
                    type="number"
                  />
                </Grid>
              </ConditionField>

              {renderGridDivider()}

              <Grid item xs={1}>
                <TextField
                  className={classes.customTextField}
                  name="serviceCodes"
                  label={<TransField i18nKey="service" />}
                  multiline
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="carrierIds"
                  label={<TransField i18nKey="carrier" />}
                  options={carrierOptions}
                  multiple
                  disabled={isEditing}
                  validateRequired={false}
                  required={!isAncillaryProductWithBookingSalesLevel}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  className={classes.customTextField}
                  name="lineNumbers"
                  label={<TransField i18nKey="lineNumbers" />}
                  size="medium"
                  multiline
                />
              </Grid>

              {renderGridDivider()}

              <Grid item xs={1}>
                <SelectField
                  name="primaryFareTypeId"
                  label={<TransField i18nKey="primaryFareType" />}
                  options={fareTypeOptions.filter(({ value }) =>
                    values.subCategoryClassificationId ===
                    'PRODUCT_SUB_CATEGORY.SEAT_SELECTION_FEE'
                      ? value === 'FARE_TYPE.FIXED'
                      : true
                  )}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="fareCategoryId"
                  label={<TransField i18nKey="fareCategory" />}
                  options={fareCategoryOptions}
                  required
                />
              </Grid>
              <ConditionField when="primaryFareTypeId" is="FARE_TYPE.DISTANCE">
                <Grid item xs={1}>
                  <RadioGroupField
                    name="distanceFareImplementationMethodId"
                    label={<TransField i18nKey="method" />}
                    options={distanceFareImplementationMethodOptions}
                    defaultValue="DISTANCE_FARE_IMPLEMENTATION_METHOD.UNIT_FARE"
                    inline
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="pricingDistanceTypeId"
                    label={<TransField i18nKey="distanceType" />}
                    options={pricingDistanceTypeOptions}
                    defaultValue="PRICING_DISTANCE_TYPE.ROUTE"
                    inline
                  />
                </Grid>
              </ConditionField>
              <ConditionField
                when="categoryClassificationId"
                is={[
                  ProductObjectType.ADMISSION,
                  ProductObjectType.TRAVEL_PASS,
                ]}
              >
                {renderGridDivider()}

                <Grid item xs={1}>
                  <RadioGroupField
                    name="arePromotionalDiscountsAllowed"
                    label={
                      <TransField i18nKey="arePromotionalDiscountsAllowed" />
                    }
                    options="BOOL_ONLY"
                    onChange={() => form.change('promotionalDiscountId')}
                    inline
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="promotionalDiscountId"
                    label={<TransField i18nKey="promotionalDiscount" />}
                    disabled={!values.arePromotionalDiscountsAllowed}
                    options={promotionalDiscountOptions}
                    showEmptyOption
                  />
                </Grid>
              </ConditionField>
              <ConditionField
                when="categoryClassificationId"
                is={ProductObjectType.ADMISSION}
              >
                {renderGridDivider()}

                <Grid item xs={1}>
                  <TextField
                    name="passengerCountFrom"
                    label={<TransField i18nKey="passengerCountFrom" />}
                    type="number"
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="passengerCountTo"
                    label={<TransField i18nKey="passengerCountTo" />}
                    type="number"
                  />
                </Grid>
              </ConditionField>
              <ConditionField
                when="categoryClassificationId"
                is={ProductObjectType.ADMISSION}
              >
                {renderGridDivider()}

                <Grid item xs={1}>
                  <SelectField
                    name="reducedMobilityTypeIds"
                    label={<TransField i18nKey="prmNeeds" />}
                    options={reducedMobilityTypeOptions}
                    multiple
                  />
                </Grid>
              </ConditionField>
              <Grid
                item
                xs="auto"
                sx={{ ml: 'auto', mt: '24px' }}
                alignItems="flex-end"
              >
                <Stack direction="row" spacing={2}>
                  {isEditing && (
                    <Button
                      startIcon={<Icon name="clone" />}
                      disabled={submitting}
                      onClick={handleCopy}
                    >
                      <TransButton i18nKey="copy" />
                    </Button>
                  )}
                  <Button onClick={handleReset} disabled={!dirty}>
                    <TransButton i18nKey="resetChanges" />
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<Icon name="check" />}
                    disabled={submitting}
                    type="submit"
                  >
                    <TransButton i18nKey="save" />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </form>
        <TariffFormAccordion />
      </FormProvider>
    </DrawerForm>
  );
};
