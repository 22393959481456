import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { productCategoryMappingSelector } from 'features/product/productSelector';
import type { Option } from '@fleet/shared/dto/option';
import {
  Icon,
  SelectField,
  Tooltip,
  api,
  useField,
  useFormContext,
  makeClassificationOptions,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { useSelector } from 'store/utils';
import { fetchProductCategories } from 'features/product/productService';
import { Classifier } from '@fleet/shared/dto/classifier';
import qs from 'qs';
import { ProductCategory } from 'dto/productCategory';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { TransTooltip } from 'i18n/trans/tooltip';

interface InventoryClassFieldProps {}

export const InventoryClassField: FC<InventoryClassFieldProps> = () => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const [categories, setCategories] = useState<Array<ProductCategory>>([]);
  const [options, setInventoryClassOptions] = useState<Array<Option<number>>>(
    []
  );

  const { resetFieldState, change } = useFormContext();
  const {
    input: { value: categoryId },
  } = useField('categoryId', {
    subscription: { value: true },
  });
  const {
    input: { value: ownerId },
    meta: { dirty: isOwnerDirty },
  } = useField('ownerId', {
    subscription: { value: true, dirty: true },
  });

  const isOwnerCoTax = useMemo(
    () =>
      businessEntities.find(({ id }) => id === ownerId)?.contactType ===
      'CO_TAXATION_GROUP',
    [businessEntities, ownerId]
  );

  useEffect(() => {
    if (ownerId && isOwnerDirty) {
      change('inventoryClassIds', []);
    }
  }, [ownerId, change, isOwnerDirty]);

  const categoryMapping = useSelector(productCategoryMappingSelector);
  const category = useMemo(
    () => categories.find(({ id }) => id === categoryId),
    [categories, categoryId]
  );

  const required = useMemo(() => {
    const map = categoryMapping.reduce(
      (acc, { categoryClassification, requiresInventory }) => {
        acc[categoryClassification.id] = requiresInventory;
        return acc;
      },
      {} as Record<string, boolean>
    );
    return Boolean(category && map[category.category.id]);
  }, [category, categoryMapping]);

  const fetchInventoryClassOptions = useCallback(async (ownerId: number) => {
    setInventoryClassOptions(
      makeClassificationOptions<Classifier<number>>(
        (
          await api.get(
            `/inventory-classes?${qs.stringify({
              ownerId,
            })}`
          )
        ).data.items
      )
    );
  }, []);

  const fetchProductCategoryOptions = useCallback(async (ownerId: number) => {
    setCategories(await fetchProductCategories(ownerId));
  }, []);

  useEffect(() => {
    if (ownerId) {
      fetchInventoryClassOptions(ownerId);
      fetchProductCategoryOptions(ownerId);
    }
  }, [ownerId, fetchInventoryClassOptions, fetchProductCategoryOptions]);

  useEffect(() => {
    if (categoryId) {
      resetFieldState('inventoryClassIds');
    }
  }, [categoryId, resetFieldState]);

  if (!categoryId) {
    return null;
  }

  return (
    <SelectField
      name="inventoryClassIds"
      label={
        <>
          <TransField i18nKey="inventoryClass" />
          {isOwnerCoTax && (
            <Tooltip content={<TransTooltip i18nKey="inventoryClassLimit" />}>
              <Icon name="info-circle" sx={{ marginLeft: '5px' }} />
            </Tooltip>
          )}
        </>
      }
      options={options}
      multiple={isOwnerCoTax}
      required={required}
    />
  );
};
