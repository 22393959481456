import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { ProductPayload } from 'dto/product';
import { setCurrentProduct } from 'features/product/productActions';
import { Button, CardHeader, DrawerForm, DrawerFormProps, FormControl, FormProvider, Icon, } from '@fleet/shared';
import { useHistory, useParams } from 'react-router-dom';
import { CardContent, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ProductDeleteModal } from 'routes/products/ProductDeleteModal';
import { ProductAccordion } from 'routes/products/ProductAccordion';
import { useDispatch } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { Tooltip } from '@fleet/shared/mui';
import { type FormRenderProps } from '@fleet/shared/form';

interface ProductFormProps<T extends object> {
  formProps: FormRenderProps<T>;
  children: ReactNode;
}

export const ProductForm = <T extends ProductPayload>({
  formProps,
  children,
}: ProductFormProps<T>) => {
  const history = useHistory();
  const { action, id } = useParams<{ action: string; id?: string }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    history.replace('/products');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        dispatch(setCurrentProduct(undefined));
        handleGoBack();
      }
    },
    [dispatch, handleGoBack]
  );

  const { form, dirty, handleSubmit, submitting, values } = formProps;

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <FormProvider form={form}>
        <CardHeader
          isLight
          title={
            <Typography variant="subtitle">
              {isEditing ? (
                values?.description
              ) : (
                <TransSubtitle i18nKey="newProduct" />
              )}
            </Typography>
          }
          action={
            <>
              {isEditing && <ProductDeleteModal />}
              <IconButton aria-label="close" onClick={handleGoBack}>
                <Tooltip
                  content={<TransButton i18nKey="close" />}
                  delay={500}
                >
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            </>
          }
        />
        <CardContent component="form" onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            {children}
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" flexWrap="nowrap">
                <FormControl label="&nbsp;">
                  <Button
                    variant="text"
                    sx={{ whiteSpace: 'nowrap' }}
                    {...(!isEditing && { onClick: handleGoBack })}
                    {...(isEditing && {
                      onClick: handleReset,
                      disabled: !dirty,
                    })}
                  >
                    <TransButton
                      i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                    />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    variant="contained"
                    icon={isEditing ? 'check' : 'plus'}
                    type="submit"
                    disabled={submitting}
                  >
                    <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </FormProvider>
      {isEditing && <ProductAccordion />}
    </DrawerForm>
  );
};
