import { Classifier } from '@fleet/shared/dto/classifier';
import { ProductObjectType } from 'dto/product';

export interface AftersalesRuleset {
  aftersalesProfileId: string | null;
  businessEntity: Classifier<number>;
  id: string;
  isFeeAppliedOnlyForDownsell: boolean | null;
  productId: string | null;
  salesChannelTypes: Array<Classifier>;
  validityPeriod: {
    from: string;
    to?: string;
    timeZone?: string;
  };
}

export interface AftersalesRulesetPayload
  extends Omit<
    AftersalesRuleset,
    'aftersalesProfileId' | 'businessEntity' | 'productId' | 'salesChannelTypes'
  > {
  salesChannelTypeIds?: Array<string>;
}

export interface AftersalesRuleBase {
  id: string;
  rulesetId: string;
  activityType: Classifier;
  objectType: ProductObjectType;
  amount: number;
  minimumAmount: number;
  maximumAmount: number;
  feeCalculationType: Classifier;
  currency: Classifier;
}

export interface DefaultAftersalesRule extends AftersalesRuleBase {
  activityTimeRestrictionType: Classifier;
  activityTimeRestrictionInMinutes: number;
}

export interface TravelPassAftersalesRule extends AftersalesRuleBase {
  isPartialRefundAllowed: boolean;
}

export interface AftersalesRuleBasePayload
  extends Omit<
    AftersalesRuleBase,
    'activityType' | 'feeCalculationType' | 'currency'
  > {
  activityTypeId: string;
  feeCalculationTypeId: string;
  currencyId: string;
}

export interface DefaultAftersalesRulePayload
  extends AftersalesRuleBasePayload {
  activityTimeRestrictionTypeId: string;
  activityTimeRestrictionInMinutes: number;
}

export interface TravelPassAftersalesRulePayload
  extends AftersalesRuleBasePayload {
  isPartialRefundAllowed: boolean;
}

export const allowedAftersalesActivityMap: Record<
  ProductObjectType,
  Array<string>
> = {
  'PRODUCT_CATEGORY.ADMISSION': [
    'AFTERSALES_ACTIVITY.REFUND',
    'AFTERSALES_ACTIVITY.CANCEL',
    'AFTERSALES_ACTIVITY.UPGRADE',
    'AFTERSALES_ACTIVITY.SEAT_CHANGE',
    'AFTERSALES_ACTIVITY.REBOOK',
  ],
  'PRODUCT_CATEGORY.ANCILLARY': [
    'AFTERSALES_ACTIVITY.REFUND',
    'AFTERSALES_ACTIVITY.CANCEL',
    'AFTERSALES_ACTIVITY.REBOOK',
  ],
  'PRODUCT_CATEGORY.FEE': [
    'AFTERSALES_ACTIVITY.REFUND',
    'AFTERSALES_ACTIVITY.REBOOK',
  ],
  'PRODUCT_CATEGORY.RESERVATION': [
    'AFTERSALES_ACTIVITY.REFUND',
    'AFTERSALES_ACTIVITY.CANCEL',
    'AFTERSALES_ACTIVITY.UPGRADE',
    'AFTERSALES_ACTIVITY.SEAT_CHANGE',
    'AFTERSALES_ACTIVITY.REBOOK',
  ],
  'PRODUCT_CATEGORY.SUPPLEMENT': [
    'AFTERSALES_ACTIVITY.REFUND',
    'AFTERSALES_ACTIVITY.REBOOK',
  ],
  'PRODUCT_CATEGORY.TRAVEL_PASS': ['AFTERSALES_ACTIVITY.REFUND'],
};
