import type { Option } from '@fleet/shared/dto/option';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ConditionField,
  makeClassificationOptions,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { fetchReservationProducts } from 'features/product/productService';
import {
  Product,
  ProductAdmission,
  ProductAdmissionPayload,
  ProductObjectType,
} from 'dto/product';
import { useProductSelector } from 'features/product/productSelector';
import { ProductForm } from 'routes/products/ProductForm';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';
import { Classifier } from '@fleet/shared/dto/classifier';
import dtid from 'helpers/dtid';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

const isProductAdmission = (product?: Product): product is ProductAdmission =>
  Boolean(product) && product?.objectType === ProductObjectType.ADMISSION;

interface AdmissionFormProps {}

export const AdmissionForm: FC<AdmissionFormProps> = () => {
  const [reservationProductOptions, setReservationProductOptions] = useState<
    Array<Option<string>>
  >([]);
  const reservationOptions = useClassificationOptions(
    ClassificationGroup.RESERVATION_OPTION
  );
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );
  const product = useProductSelector(isProductAdmission);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const inventoryClassIds = useProductFormInventoryClasses(product);
  const businessEntities = useSelector(businessEntitiesSelector);

  const initialValues: Partial<ProductAdmissionPayload> = useMemo(
    () => ({
      ...commonFields,
      ...(product && {
        ...product,
        inventoryClassIds,
        reservationOptionId: product.reservationOption?.id,
        reservationProductId: product.reservationProduct?.id,
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductAdmissionPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  const handleGetReservationProducts = useCallback(async () => {
    setReservationProductOptions(
      makeClassificationOptions<Classifier>(
        await fetchReservationProducts(product?.ownerId)
      )
    );
  }, [product?.ownerId]);

  const handleReservationOptionIdChange = useCallback(
    (value) =>
      value === 'RESERVATION_OPTION.NOT_AVAILABLE' &&
      formProps.form.change('reservationProductId'),
    [formProps.form]
  );

  useEffect(() => {
    handleGetReservationProducts();
  }, [handleGetReservationProducts]);

  return (
    <div data-testid={dtid.productsDetailsAdmissionForm}>
      <ProductForm formProps={formProps}>
        <Grid item xs={1}>
          <SelectField
            name="objectType"
            label={<TransField i18nKey="category" />}
            options={categoriesOptions}
            disabled
            required
          />
        </Grid>
        <Grid item xs={1}>
          <SelectOwnerField
            businessEntities={businessEntities}
            allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
            disabled
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="description"
            label={<TransField i18nKey="description" />}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="code"
            label={<TransField i18nKey="code" />}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <InventoryClassField />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            name="reservationOptionId"
            label={<TransField i18nKey="reservationOption" />}
            options={reservationOptions}
            onChange={handleReservationOptionIdChange}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <ConditionField
            when="reservationOptionId"
            is={['RESERVATION_OPTION.MANDATORY', 'RESERVATION_OPTION.OPTIONAL']}
          >
            <SelectField
              name="reservationProductId"
              label={<TransField i18nKey="reservationProduct" />}
              options={reservationProductOptions}
              required
            />
          </ConditionField>
        </Grid>
      </ProductForm>
    </div>
  );
};
