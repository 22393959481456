import Card from '@mui/material/Card';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Drawer, Loadable, SearchResult } from '@fleet/shared';
import { Box, CardContent, Grid, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { Layout } from '@fleet/shared/components/Layout';
import { TransTitle } from 'i18n/trans/title';
import { ProductCategoryForm } from 'routes/productCategory/ProdcutCategoryForm';
import { productCategoryLoadingSelector } from 'features/loading/loadingSelectors';
import { getProductCategoryMappings } from 'features/productCategory/productCategoryActions';
import { productCategoryListSelector } from 'features/productCategory/productCategorySelectors';
import { ProductCategorySearchForm } from 'routes/productCategory/ProductCategorySearchForm';
import dtid from 'helpers/dtid';

const useStyles = makeStyles(
  (theme) => ({
    productCategoryCard: {
      height: '100%',
      background: theme.palette.background.default,
      cursor: 'pointer',

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
      },
    },
  }),
  {
    name: 'ProductCategory',
  }
);

interface ProductCategoryProps {}

export const ProductCategory: FC<ProductCategoryProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(productCategoryLoadingSelector);
  const productCategoryList = useSelector(productCategoryListSelector);
  const history = useHistory();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();

  const isDrawerOpen = useMemo(
    () => !!(action === 'edit' && id) || action === 'create',
    [action, id]
  );

  const handleCloseEditForm = useCallback(() => {
    history.replace('/product-category');
  }, [history]);

  useEffect(() => {
    dispatch(getProductCategoryMappings());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Drawer
            elevation={0}
            anchor="right"
            disablePortal={false}
            open={isDrawerOpen}
            onClose={handleCloseEditForm}
            data-testid={dtid.addCategoryDrawer}
          >
            <ProductCategoryForm />
          </Drawer>
        }
      >
        <>
          <ProductCategorySearchForm />
          <Divider />
          <SearchResult results={productCategoryList.length} loading={loading}>
            <CardContent sx={{ p: 3, flexGrow: 1 }}>
              <Grid container spacing={3}>
                {productCategoryList?.map(
                  ({ id, description, owner, category, subCategory }) => (
                    <Grid key={id} item sm={4} md={3}>
                      <Box
                        p={2}
                        component={Card}
                        variant="outlined"
                        className={classes.productCategoryCard}
                        square
                        onClick={() =>
                          history.push(`/product-category/edit/${id}`)
                        }
                      >
                        <Typography
                          style={{
                            wordBreak: 'break-word',
                            marginBottom: '16px',
                          }}
                          variant="subtitle"
                          component="h3"
                          color="text.primary"
                        >
                          {description}
                        </Typography>
                        <Typography
                          style={{ wordBreak: 'break-word', fontWeight: 400 }}
                          variant="subtitle"
                          component="h3"
                          color="text.secondary"
                        >
                          <TransTitle i18nKey="owner" />
                        </Typography>
                        <Typography
                          style={{
                            wordBreak: 'break-word',
                            fontWeight: 400,
                            color: '#2C2C2C',
                            marginBottom: '12px',
                          }}
                          variant="subtitle"
                          component="h3"
                        >
                          {owner.name}
                        </Typography>
                        <Typography
                          style={{ wordBreak: 'break-word', fontWeight: 400 }}
                          variant="subtitle"
                          component="h3"
                          color="text.secondary"
                        >
                          <TransTitle i18nKey="group" />
                        </Typography>
                        <Typography
                          style={{
                            wordBreak: 'break-word',
                            fontWeight: 400,
                            color: '#2C2C2C',
                            marginBottom: '12px',
                          }}
                          variant="subtitle"
                          component="h3"
                        >
                          {category?.name || '-'}
                        </Typography>
                        <Typography
                          style={{ wordBreak: 'break-word', fontWeight: 400 }}
                          variant="subtitle"
                          component="h3"
                          color="text.secondary"
                        >
                          <TransTitle i18nKey="subGroup" />
                        </Typography>
                        <Typography
                          style={{
                            wordBreak: 'break-word',
                            fontWeight: 400,
                            color: '#2C2C2C',
                            marginBottom: '12px',
                          }}
                          variant="subtitle"
                          component="h3"
                        >
                          {subCategory?.name || '-'}
                        </Typography>
                        {/*<Typography*/}
                        {/*  style={{ wordBreak: 'break-word', fontWeight: 400 }}*/}
                        {/*  variant="subtitle"*/}
                        {/*  component="h3"*/}
                        {/*  color="text.secondary"*/}
                        {/*>*/}
                        {/*  <TransTitle i18nKey="aftersales" />*/}
                        {/*</Typography>*/}
                        {/*<Typography*/}
                        {/*  style={{*/}
                        {/*    wordBreak: 'break-word',*/}
                        {/*    fontWeight: 400,*/}
                        {/*    color: '#2C2C2C',*/}
                        {/*  }}*/}
                        {/*  variant="subtitle"*/}
                        {/*  component="h3"*/}
                        {/*>*/}
                        {/*  {areAftersalesActivitiesAllowed ? (*/}
                        {/*    <Icon name="tick" color="success" />*/}
                        {/*  ) : (*/}
                        {/*    <Icon name="close" />*/}
                        {/*  )}*/}
                        {/*</Typography>*/}
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </SearchResult>
        </>
      </Layout>
    </Loadable>
  );
};
