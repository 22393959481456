import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumns } from '@fleet/shared';
import {
  allowedAftersalesActivityMap,
  TravelPassAftersalesRule,
  TravelPassAftersalesRulePayload,
} from 'dto/aftersales';
import { TransTableHead } from 'i18n/trans/table';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import {
  createOrUpdateProductAfterSalesRule,
  fetchProductAfterSalesRules,
} from 'features/product/productService';
import { AftersalesRulesTable } from 'routes/products/productAccordion/aftersalesRulesets/AftersalesRulesTable';
import { MinAndMaxAmountField } from 'routes/products/productAccordion/aftersalesRulesets/rules/MinAndMaxAmountField';

interface TravelPassAftersalesRulesProps {
  productId: string;
  rulesetId: string;
}

export const TravelPassAftersalesRules: FC<TravelPassAftersalesRulesProps> = ({
  productId,
  rulesetId,
}) => {
  const [rules, setRules] = useState<Array<TravelPassAftersalesRule>>([]);
  const product = useSelector(currentProductSelector);
  const activityOptions = useClassificationOptions(
    ClassificationGroup.AFTERSALES_ACTIVITY
  );
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const feeCalculationTypeOptions = useClassificationOptions(
    ClassificationGroup.FEE_CALCULATION_TYPE
  );

  const fetchRules = useCallback(async () => {
    setRules(
      await fetchProductAfterSalesRules<TravelPassAftersalesRule>(rulesetId)
    );
  }, [rulesetId]);

  useEffect(() => {
    if (productId && rulesetId) {
      fetchRules();
    }
  }, [productId, rulesetId, fetchRules]);

  const columns: TableColumns<TravelPassAftersalesRule> = useMemo(
    () => [
      {
        id: 'activityType.id',
        accessor: ({ activityType }) => activityType?.id,
        Header: <TransTableHead i18nKey="activityType" />,
        type: 'select',
        editableProps: {
          options: activityOptions.map(({ value, ...rest }) => ({
            ...rest,
            value,
            isValid:
              allowedAftersalesActivityMap[product!.objectType].includes(value),
          })),
        },
      },
      {
        accessor: 'isPartialRefundAllowed',
        Header: <TransTableHead i18nKey="isPartialRefundAllowed" />,
        type: 'checkbox',
        editableProps: {
          required: false,
          defaultValue: false,
        },
      },
      {
        id: 'feeCalculationType.id',
        accessor: ({ feeCalculationType }) => feeCalculationType?.id,
        Header: <TransTableHead i18nKey="feeValueType" />,
        type: 'select',
        editableProps: {
          options: feeCalculationTypeOptions,
        },
      },
      {
        accessor: 'amount',
        Header: <TransTableHead i18nKey="feeValue" />,
        editableProps: {
          type: 'number',
        },
      },
      {
        accessor: 'minimumAmount',
        Header: <TransTableHead i18nKey="minMaxAmount" />,
        Cell: MinAndMaxAmountField,
      },
      {
        id: 'currency.id',
        accessor: ({ currency }) => currency?.id,
        Header: <TransTableHead i18nKey="currency" />,
        type: 'select',
        editableProps: {
          options: currencyOptions,
        },
      },
    ],
    [activityOptions, currencyOptions, feeCalculationTypeOptions, product]
  );

  const handleRowUpdate = useCallback(
    async ({
      activityType,
      currency,
      feeCalculationType,
      ...rest
    }: TravelPassAftersalesRule) => {
      const payload: TravelPassAftersalesRulePayload = {
        ...rest,
        activityTypeId: activityType.id,
        objectType: product!.objectType,
        currencyId: currency?.id,
        feeCalculationTypeId: feeCalculationType?.id,
      };

      await createOrUpdateProductAfterSalesRule(rulesetId, payload);
      await fetchRules();
    },
    [product, rulesetId, fetchRules]
  );

  return (
    <AftersalesRulesTable
      data={rules}
      columns={columns}
      rulesetId={rulesetId}
      fetchData={fetchRules}
      updateRow={handleRowUpdate}
    />
  );
};
