import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import {
  ancillaryTypeLoadingSelector,
  ancillaryTypesLoadingSelector,
} from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { AncillaryTypesDetails } from 'routes/ancillaryTypes/AncillaryTypesDetails';
import { AncillaryTypesTable } from 'routes/ancillaryTypes/AncillaryTypesTable';
import { useSelector } from 'store/utils';
import dtid from 'helpers/dtid';

interface AncillaryTypesProps {}

export const AncillaryTypes: FC<AncillaryTypesProps> = () => {
  const history = useHistory();
  const loadingAncillaryTypes = useSelector(ancillaryTypesLoadingSelector);
  const loadingAncillaryType = useSelector(ancillaryTypeLoadingSelector);

  return (
    <Loadable loading={loadingAncillaryTypes || loadingAncillaryType}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="ancillaryTypes" />}>
            <AddButton
              onClick={() => history.push('/ancillary-types/create')}
              data-testid={dtid.ancillaryTypesAddNew}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/ancillary-types/:action(create|edit)/:id?"
              component={AncillaryTypesDetails}
              exact
            />
          </Switch>
        }
        data-testid="ancillary-types-layout"
      >
        <AncillaryTypesTable />
      </Layout>
    </Loadable>
  );
};
