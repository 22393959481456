import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  ConditionField,
  FormProvider,
  Loadable,
  SelectOwnerField,
  useForm,
} from '@fleet/shared';
import { SelectField, TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { useDispatch, useSelector } from 'store/utils';
import {
  createProductCategory,
  getProductCategoryById,
  getProductCategoryList,
  setProductCategory,
  updateProductCategory,
} from 'features/productCategory/productCategoryActions';
import {
  currentProductCategorySelector,
  productCategoryFilterSelector,
  productCategoryMappingsSelector,
} from 'features/productCategory/productCategorySelectors';
import { productCategoryLoadingSelector } from 'features/loading/loadingSelectors';
import { useAlert } from 'react-alert';
import { ProductCategoryPayload } from 'dto/productCategory';
import { TransAlert } from 'i18n/trans/alert';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import dtid from 'helpers/dtid';
import { validate } from '@fleet/shared/form/validate';
import { TransError } from 'i18n/trans/error';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

interface ProductCategoryFormProps {}

export const ProductCategoryForm: FC<ProductCategoryFormProps> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(productCategoryFilterSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const currentProductCategory = useSelector(currentProductCategorySelector);
  const loading = useSelector(productCategoryLoadingSelector);
  const categoryMappings = useSelector(productCategoryMappingsSelector);
  const history = useHistory();
  const alert = useAlert();
  const categories = categoryMappings.map(({ categoryClassification }) => ({
    value: categoryClassification.id,
    label: categoryClassification.name,
  }));

  useEffect(() => {
    dispatch(setProductCategory());
    if (id) {
      dispatch(getProductCategoryById(id));
    }

    return () => {
      dispatch(setProductCategory());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/product-category');
  }, [history]);

  const onSubmit = useCallback(
    async (payload: ProductCategoryPayload) => {
      const res = await dispatch(
        (payload.id ? updateProductCategory : createProductCategory)(payload)
      ).unwrap();

      if (res) {
        alert.success(
          <TransAlert
            i18nKey={
              payload.id ? 'productCategorySaved' : 'productCategoryCreated'
            }
          />
        );
        closeDrawer();
        await dispatch(getProductCategoryList(filter ?? {}));
      }
    },
    [alert, closeDrawer, dispatch, filter]
  );

  const initialValues = useMemo(
    () => ({
      ownerId: currentBusinessEntityId,
      areAftersalesActivitiesAllowed: false,
      ...(currentProductCategory && {
        categoryId: currentProductCategory.category.id,
        description: currentProductCategory.description,
        id: currentProductCategory.id,
        subCategoryId: currentProductCategory.subCategory?.id,
        ownerId: currentProductCategory.owner.id,
      }),
    }),
    [currentBusinessEntityId, currentProductCategory]
  );

  const { form, handleSubmit, values } = useForm<ProductCategoryPayload>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const subCategories =
    categoryMappings
      .find(
        (category) => category.categoryClassification.id == values.categoryId
      )
      ?.subcategories.map((subCategory) => ({
        value: subCategory.id,
        label: subCategory.name,
      })) ?? [];

  return (
    <Loadable loading={loading}>
      <FormProvider form={form}>
        <Stack
          sx={{ width: 400, height: '100%' }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            title={
              <Typography variant="subtitle">
                <TransTitle i18nKey="productCategory" />
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
            <TextField
              label={<TransField i18nKey="description" />}
              name="description"
              margin="normal"
              required
              data-testid={dtid.productCategoryDescriptionField}
            />
            <SelectOwnerField
              businessEntities={businessEntities}
              allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
              margin="normal"
              disabled={!!id}
            />
            <SelectField
              label={<TransField i18nKey="group" />}
              name="categoryId"
              options={categories}
              margin="normal"
              required
              data-testid="product-category-group-select"
              onChange={() => {
                form.change('subCategoryId');
                form.resetFieldState('subCategoryId');
              }}
            />
            <ConditionField when="categoryId" is="PRODUCT_CATEGORY.FEE">
              {(isFee) => (
                <SelectField<string>
                  label={<TransField i18nKey="subGroup" />}
                  name="subCategoryId"
                  options={subCategories}
                  margin="normal"
                  validate={(
                    subCategoryId: string,
                    allValues: ProductCategoryPayload
                  ) =>
                    allValues.categoryId === 'PRODUCT_CATEGORY.FEE'
                      ? validate.required(<TransError i18nKey="required" />)(
                          subCategoryId
                        )
                      : null
                  }
                  validateRequired={false}
                  required={isFee}
                />
              )}
            </ConditionField>
          </CardContent>
          <CardActions
            sx={{ padding: 3, justifyContent: 'flex-end', boxShadow: 2 }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            {id ? (
              <Button type="submit" variant="contained" color="primary">
                <TransButton i18nKey="save" />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="plus" />}
                data-testid={dtid.categoryDrawerAddButton}
              >
                <TransButton i18nKey="add" />
              </Button>
            )}
          </CardActions>
        </Stack>
      </FormProvider>
    </Loadable>
  );
};
