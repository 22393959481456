import { FormField, TextField, useField } from '@fleet/shared';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { Cell } from 'react-table';
import { AftersalesRuleBase, DefaultAftersalesRule } from 'dto/aftersales';

export const MinAndMaxAmountField: FC<{ cell: Cell<DefaultAftersalesRule> }> =
  ({ cell: { row } }) => {
    const { state, original } = row;
    const {
      input: { value },
    } = useField<AftersalesRuleBase>(`rows[${row.id}]`);
    const disabled =
      value.feeCalculationType?.id === 'FEE_CALCULATION_TYPE.FIXED_AMOUNT';

    if (state.editable) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormField
            name={`rows[${row.id}].minimumAmount`}
            render={({ input }) => (
              <TextField {...input} type="number" disabled={disabled} />
            )}
          />
          <span>-</span>
          <FormField
            name={`rows[${row.id}].maximumAmount`}
            render={({ input }) => (
              <TextField {...input} type="number" disabled={disabled} />
            )}
          />
        </Stack>
      );
    }

    return (
      <div>{`${original.minimumAmount ?? ''} - ${
        original.maximumAmount ?? ''
      }`}</div>
    );
  };
