import {
  ConfirmDeleteModal,
  Icon,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
  useModal,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import { BundledProduct } from 'dto/product';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { useCallback, useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { AddModal } from 'routes/products/productAccordion/bundledProducts/AddModal';
import { useDispatch, useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import { updateBundledProducts } from 'features/product/productActions';
import { TransModal } from 'i18n/trans/modal';
import { TransField } from 'i18n/trans/field';

export const BundledProducts = () => {
  const dispatch = useDispatch();
  const product = useSelector(currentProductSelector)!;
  const {
    open: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useModal();
  const {
    open: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useModal();

  const columns: TableColumns<BundledProduct> = useMemo(
    () => [
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="includedProducts" />,
      },
      {
        id: 'category',
        accessor: ({ categoryClassificationId }) =>
          categoryClassificationId.name,
        Header: <TransTableHead i18nKey="type" />,
      },
    ],
    []
  );
  const getRowId = useCallback((row: BundledProduct) => row.id, []);
  const table = useTable(
    {
      data: product.bundledProducts,
      columns,
      getRowId,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const updatePayload = useMemo(() => {
    const productIdsToRemove = Object.keys(table.state.selectedRowIds);
    const payloadIds = product.bundledProducts
      .filter(({ id }) => !productIdsToRemove.includes(id))
      .map(({ id }) => id);
    return {
      id: product.id,
      productIds: payloadIds,
    };
  }, [product, table.state.selectedRowIds]);

  const handleRemoveProducts = useCallback(async () => {
    await dispatch(updateBundledProducts(updatePayload)).unwrap();

    onDeleteClose();
  }, [dispatch, updatePayload, onDeleteClose]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          variant="text"
          color="error"
          onClick={onDeleteOpen}
          startIcon={<Icon name="trash" size={20} />}
          disabled={Object.keys(table.state.selectedRowIds).length === 0}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={onAddOpen}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getHeaderGroupProps={{
          sx: {
            background: 'white',
          },
        }}
        table={table}
        emptyLabel={<TransField i18nKey="noProductsInBundle" />}
      />
      <AddModal open={isAddOpen} onClose={onAddClose} />
      <ConfirmDeleteModal
        handleDelete={handleRemoveProducts}
        title={<TransModal i18nKey="deleteProductsInBundle" />}
        description={
          <TransModal
            i18nKey="productsInBundleDeletionDescription"
            values={{ count: table.selectedFlatRows.length }}
          />
        }
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
      />
    </>
  );
};
